/* eslint-disable react/no-array-index-key */
import { Row, Typography } from 'antd';
import Interweave from 'interweave';
import {
    ComponentClass,
    FunctionComponent,
    ReactElement,
    useContext,
} from 'react';
import { FormContext } from '../Stepper';
import { getPatientName, PhoneNumber } from '../../utils/textUtils';
import { confirmation as confirmationContent } from '../../staticContent.json';
import './interstitialContent.less';

const { Title, Paragraph } = Typography;

interface IconProps {
    className?: string;
}
interface Props {
    Icon?: FunctionComponent<IconProps> | ComponentClass<IconProps>;
    title: string;
    content: string;
    content2?: string;
    className?: string;
}

function InterstitialContent({
    Icon,
    title,
    content,
    content2,
    className,
}: Props): ReactElement {
    const form = useContext(FormContext);
    const patientName = form?.getFieldValue('firstName');
    return (
        <div className={className}>
            {Icon && (
                <Row justify="center">
                    <Icon className="interstitial-icon" />
                </Row>
            )}
            <Row justify="center">
                <Title className="center-text interstitial-title">
                    {getPatientName(title, patientName)}
                </Title>
            </Row>
            <Row justify="center">
                <Paragraph
                    className={`center-text interstitial-paragraph ${
                        content === confirmationContent.desc ? 'text-dark' : ''
                    }`}
                >
                    <Interweave
                        content={getPatientName(content, patientName)}
                    />
                    {content2 && (
                        <>
                            <br />
                            <br />
                            <PhoneNumber>
                                {getPatientName(content2, patientName)}
                            </PhoneNumber>
                        </>
                    )}
                </Paragraph>
            </Row>
        </div>
    );
}

InterstitialContent.defaultProps = {
    Icon: undefined,
    content2: undefined,
    className: '',
};

export default InterstitialContent;
