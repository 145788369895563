import { ReactElement, useEffect, useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { Form as AntForm } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { ReactComponent as NewHealthProfileIcon } from './NewHealthProfileIcon.svg';
import Stepper from '../../components/Stepper';
import HealthProfile from '../HealthProfile';
import ClinicalProtocol from '../ClinicalProtocol';
import CheckoutComplete from '../CheckoutComplete/CheckoutComplete';
import UserSignUp from '../UserSignUp';
import './form.less';
import URAC from '../URAC';
import VideoUpload from '../VideoUpload';
import { ClinicalQuestionType } from '../ClinicalProtocol/ClinicalProtocol';
import InterstitialContent from '../../components/InterstitialContent';
import Step from './Step';
import PharmacySearch from '../PharmacySearch';
import PharmacySelect from '../PharmacySelect';
import ConsultationSummary from '../../components/ConsultationSummary';
import Checkout from '../Checkout';
import UserFilter from '../UserFilter';
import {
    profile_new as newProfileContent,
    clinical_protocol as clinicalProtocolContent,
    video as videoContent,
    confirmation as confirmationContent,
} from '../../staticContent.json';
import OrderSummary from '../OrderSummary';
import Auth0Wrapper from '../../components/Auth0Wrapper';
import {
    getWithExpiry,
    setWithExpiry,
} from '../../helpers/localStorageWithExpiry';
import InformedConsent from '../InformedConsent';
import UserHealthProfiles from '../userHealthProfiles';

const clinicalQuestions: ClinicalQuestionType[] = [
    'weight',
    'whichEar',
    'pulling',
    'infectionLastDays',
    'symptoms',
];

const toKebabCase = (str: string): string =>
    str.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();

const {
    REACT_APP_API_URL,
    NODE_ENV,
    REACT_APP_AUTH0_AUDIENCE,
    REACT_APP_STRIPE_PUBLISHABLE_KEY = 'pk_test_51K5FoALXyRYojmcz1oLGvEPU63idon2xzosujRu8Vrxe1Sh5us9ndgRGc3n1Dx1N5A5JYgsBHD1ZxtALzE18xfYp0051oKWsRw',
} = process.env;
const isDev = NODE_ENV === 'development';
const isTest = NODE_ENV === 'test';

const stripePromise = loadStripe(REACT_APP_STRIPE_PUBLISHABLE_KEY);

interface Config {
    headers?: {
        Authorization: string;
    };
}

function Form(): ReactElement {
    const storedStripeClientSecret = getWithExpiry('stripeClientSecret');
    const [stripeClientSecret, setStripeClientSecret] = useState<string>(
        storedStripeClientSecret ? JSON.parse(storedStripeClientSecret) : ''
    );
    const { getAccessTokenSilently, isAuthenticated } = useAuth0();
    const [isBusinessHours, setIsBusinessHours] = useState<boolean | null>(
        null
    );
    const [stepForm] = AntForm.useForm();

    useEffect(() => {
        setWithExpiry(
            'stripeClientSecret',
            JSON.stringify(stripeClientSecret),
            20 * 60 * 1000
        );
    }, [stripeClientSecret]);

    const stripeUrl = `${
        (!isTest && REACT_APP_API_URL) || 'http://localhost:1080'
    }/api/payment`;
    const stripeOptions = {
        clientSecret: stripeClientSecret,
        fonts: [
            {
                cssSrc: 'https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap',
            },
        ],
        appearance: {
            variables: {
                fontFamily: 'Lato',
            },
            rules: {
                '.Label': {
                    color: 'black',
                    fontSize: '16px',
                    fontWeight: '700',
                    marginBottom: '12px',
                },
            },
        },
    };

    useEffect(() => {
        const createPaymentIntent = async (): Promise<void> => {
            const stripeConfig: Config = {};
            const token =
                !isDev && !isTest && isAuthenticated
                    ? await getAccessTokenSilently({
                          audience: REACT_APP_AUTH0_AUDIENCE,
                      })
                    : '';
            if (token) {
                stripeConfig.headers = {
                    Authorization: `Bearer ${token}`,
                };
            }
            const requestBody = {
                item: isBusinessHours
                    ? 'consult-working-hours'
                    : 'consult-out-of-hours',
            };
            axios
                .post(stripeUrl, requestBody, stripeConfig)
                .then(({ data }) => {
                    setStripeClientSecret(data.clientSecret);
                    stepForm.setFieldsValue({
                        paymentId: data.paymentIntentId,
                    });
                });
        };

        // Create new PaymentIntent if user authed, consult type known, and one doesn't already exist
        if (
            isBusinessHours !== null &&
            isAuthenticated &&
            !stripeClientSecret
        ) {
            // eslint-disable-next-line no-console
            createPaymentIntent().catch(console.error);
        }
    }, [
        getAccessTokenSilently,
        isAuthenticated,
        isBusinessHours,
        stepForm,
        stripeClientSecret,
        stripeUrl,
    ]);

    return (
        <Stepper stepForm={stepForm}>
            <Step path="" noNext>
                <UserFilter />
            </Step>
            <Step path="user-health-profiles" skip noNext>
                <UserHealthProfiles />
            </Step>
            <Step path="new-health-profile">
                <InterstitialContent
                    Icon={NewHealthProfileIcon}
                    title={newProfileContent.interstitial.title}
                    content={newProfileContent.interstitial.desc_1}
                    content2={newProfileContent.interstitial.desc_2}
                />
            </Step>
            <Step path="health-profile">
                <HealthProfile />
            </Step>
            <Step path="health-profile-complete">
                <InterstitialContent
                    title={newProfileContent.completed.title}
                    content={newProfileContent.completed.desc_1}
                    content2={newProfileContent.completed.desc_2}
                />
            </Step>
            <Step path="urac" noNext>
                <URAC />
            </Step>
            <Step path="informed-consent" noNext>
                <InformedConsent />
            </Step>
            {clinicalQuestions.map((question) => (
                <Step key={`step-${question}`} path={toKebabCase(question)}>
                    <ClinicalProtocol key={question} question={question} />
                </Step>
            ))}
            <Step path="health-questions-complete">
                <InterstitialContent
                    title={clinicalProtocolContent.completed.title}
                    content={clinicalProtocolContent.completed.desc}
                />
            </Step>
            <Step path="user-sign-up" noNext oneTime>
                <UserSignUp />
            </Step>
            <Step path="sign-up-complete">
                <InterstitialContent
                    title="Your account has been successfully verified"
                    content="Time to upload your inner ear videos"
                />
            </Step>
            <Step path="left-ear">
                <VideoUpload ear="left" />
            </Step>
            <Step path="right-ear">
                <VideoUpload ear="right" />
            </Step>
            <Step path="video-complete">
                <InterstitialContent
                    title={videoContent.ear_2_completed.title}
                    content={videoContent.ear_2_completed.desc}
                />
            </Step>
            <Step path="pharmacy-search">
                <PharmacySearch />
            </Step>
            <Step path="pharmacy-select" noNext>
                <PharmacySelect />
            </Step>
            <Step path="confirm-consult">
                <InterstitialContent
                    title={confirmationContent.title}
                    content={confirmationContent.desc}
                />
                <ConsultationSummary setIsBusinessHours={setIsBusinessHours} />
            </Step>

            <Step path="checkout" noNext>
                <Elements options={stripeOptions} stripe={stripePromise}>
                    <Checkout stripeClientSecret={stripeClientSecret} />
                </Elements>
            </Step>
            <Step path="checkout-complete" noNext>
                <CheckoutComplete />
            </Step>
            <Step path="loading" noNext>
                <div className="spin-container">
                    <LoadingOutlined />
                </div>
            </Step>
            <Step path="order-summary" noNext>
                <OrderSummary />
            </Step>
        </Stepper>
    );
}

export default Auth0Wrapper(
    Form,
    `${window.location.origin}/form/user-sign-up`
);
