import { ReactElement, useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Radio, Typography, Form, Input, Checkbox } from 'antd';
import './pharmacySelect.less';
import { LoadingOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import PharmacyCard from '../../components/PharmacyCard';
import { FormContext } from '../../components/Stepper';
import { pharmacy as content } from '../../staticContent.json';
import { getAPIPharmacies, TimePeriods } from '../../utils/pharmacyUtils';
import { getWithExpiry } from '../../helpers/localStorageWithExpiry';
import SearchCriteria from './SearchCriteria';

const { Title, Paragraph } = Typography;
const { Item } = Form;

export interface Pharmacies {
    name: string;
    milesAway: number;
    address: string;
    zipCode: string;
    phone: string;
    isOpen: boolean | string;
    openingHoursArray: TimePeriods[] | string;
    openingHoursText: string[];
}

export default function PharmacySelect(): ReactElement {
    const [isLoading, setIsLoading] = useState(false);
    const [loadingError, setLoadingError] = useState(false);
    const [openPharmacyChecked, setOpenPharmacyChecked] = useState(false);
    const history = useHistory();
    const form = useContext(FormContext);
    const selectedIndex = form?.getFieldValue('selectedPharmacyIndex');
    const existingForm = JSON.parse(getWithExpiry('formData') || '{}');
    const [pharmacyZipCode, setPharmacyZipCode] = useState(
        form?.getFieldValue('pharmacyZipCode') ?? existingForm.pharmacyZipCode
    );
    const [pharmacyName, setPharmacyName] = useState(
        form?.getFieldValue('pharmacyNameFilter')
    );
    const [pharmacies, setPharmacies] = useState<Pharmacies[] | null>(
        form?.getFieldValue('pharmacies')
    );
    const [maxIndex, setMaxIndex] = useState(selectedIndex > 9 ? 20 : 10);

    const [value, setValue] = useState<number | null>(selectedIndex);
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        if (!pharmacies && !isLoading && !loadingError) {
            const getPharmacies = async (): Promise<void> => {
                setIsLoading(true);
                const isTest = process.env.NODE_ENV === 'test';
                const token = !isTest
                    ? await getAccessTokenSilently({
                          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                      })
                    : '';
                try {
                    const response = await getAPIPharmacies(
                        pharmacyZipCode,
                        pharmacyName,
                        token,
                        openPharmacyChecked
                    );
                    setPharmacies(response);
                    setLoadingError(false);
                } catch {
                    setLoadingError(true);
                    setPharmacies(null);
                }
                setIsLoading(false);
            };
            getPharmacies();
        }
    }, [
        pharmacies,
        pharmacyName,
        pharmacyZipCode,
        openPharmacyChecked,
        isLoading,
        getAccessTokenSilently,
        loadingError,
    ]);

    const setFormValues = (index: number): void => {
        if (pharmacies) {
            const { name, address, zipCode, phone } = pharmacies[index];
            form?.setFieldsValue({
                pharmacy: { name, address, zipCode, phone },
                selectedPharmacyIndex: index,
                pharmacies,
            });
        }
    };

    let errorMessage = content.not_records_found;

    if (loadingError) {
        errorMessage = openPharmacyChecked
            ? content.no_open_pharmacies
            : content.no_pharmacies;
    }

    return (
        <>
            <Title>{content.title}</Title>
            <Paragraph>{content.desc}</Paragraph>
            <SearchCriteria
                setPharmacies={setPharmacies}
                setValue={setValue}
                setLoadingError={setLoadingError}
                zipCode={pharmacyZipCode}
                setZipCode={setPharmacyZipCode}
                setPharmacyName={setPharmacyName}
            />
            <Typography className="heading">{content.result_label}</Typography>
            <Checkbox
                checked={openPharmacyChecked}
                className="open-checkbox"
                onChange={() => {
                    setMaxIndex(10);
                    setValue(null);
                    setPharmacies(null);
                    setLoadingError(false);
                    form?.setFieldsValue({ pharmacy: null });
                    setOpenPharmacyChecked(!openPharmacyChecked);
                }}
            >
                {content.open_filter}
            </Checkbox>
            {isLoading ? (
                <LoadingOutlined />
            ) : (
                <>
                    {pharmacies ? (
                        <>
                            <Item
                                name="pharmacy"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select a pharmacy',
                                    },
                                ]}
                                valuePropName="checked"
                            >
                                <Radio.Group
                                    data-testid="pharmacy"
                                    className="full-width"
                                    value={value}
                                    onChange={(e) => {
                                        setValue(e.target.value);
                                        setFormValues(e.target.value);
                                    }}
                                >
                                    {pharmacies.map((pharmacy, index) => {
                                        return (
                                            <>
                                                {index < maxIndex && (
                                                    <PharmacyCard
                                                        key={
                                                            pharmacy.name +
                                                            pharmacy.phone
                                                        }
                                                        name={pharmacy.name}
                                                        milesAway={
                                                            pharmacy.milesAway
                                                        }
                                                        address={
                                                            pharmacy.address
                                                        }
                                                        zipCode={
                                                            pharmacy.zipCode
                                                        }
                                                        phone={pharmacy.phone}
                                                        isOpen={pharmacy.isOpen}
                                                        openingHoursArray={
                                                            pharmacy.openingHoursArray
                                                        }
                                                        openingHoursText={
                                                            pharmacy.openingHoursText
                                                        }
                                                        radio={
                                                            <Radio
                                                                className="pharmacy-select-radio-item"
                                                                value={index}
                                                            />
                                                        }
                                                        isActive={
                                                            value === index
                                                        }
                                                        handlePharmacySelect={() => {
                                                            setValue(index);
                                                            setFormValues(
                                                                index
                                                            );
                                                        }}
                                                    />
                                                )}
                                                {value === index &&
                                                    !pharmacy.isOpen && (
                                                        <div className="pharmacy-closed-warning">
                                                            {
                                                                content.closed_warning
                                                            }
                                                        </div>
                                                    )}
                                                {value === index && (
                                                    <>
                                                        <div className="grow-mobile" />
                                                        <Button
                                                            type="primary"
                                                            block
                                                            onClick={() =>
                                                                history.push(
                                                                    '/form/confirm-consult'
                                                                )
                                                            }
                                                            className="primary-form-cta"
                                                        >
                                                            Continue
                                                        </Button>
                                                    </>
                                                )}
                                            </>
                                        );
                                    })}
                                </Radio.Group>
                            </Item>
                            {pharmacies.length > maxIndex && (
                                <Button
                                    className="show-more-button text-black"
                                    onClick={() => setMaxIndex(20)}
                                >
                                    Show more
                                </Button>
                            )}
                        </>
                    ) : (
                        <div className="error-wrapper">
                            <div className="error-left-section" />
                            <div className="error-right-section">
                                <Typography>{errorMessage}</Typography>
                            </div>
                        </div>
                    )}
                </>
            )}
            {(isLoading || !pharmacies) && (
                <Item
                    name="hidden-input"
                    rules={[
                        {
                            required: true,
                            message: 'Please wait for pharmacies to load',
                        },
                    ]}
                >
                    <Input type="hidden" />
                </Item>
            )}
        </>
    );
}
