/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, Modal } from 'antd';
import { ReactElement, useState } from 'react';
import { Link, Route, useHistory } from 'react-router-dom';
import './headerWithLogo.less';
import { useAuth0 } from '@auth0/auth0-react';
import content from '../../staticContent.json';
import { ReactComponent as Logo } from '../../media/logo.svg';
import { ReactComponent as LogoutIcon } from './logout.svg';

const OrderPageCta = (): ReactElement => {
    const [logoutModalVisible, setLogoutModalVisible] = useState(false);
    const { logout } = useAuth0();
    const handleLogout = async (): Promise<void> => {
        await logout({
            returnTo: window.location.origin,
        });
    };

    const handleCancel = (): void => {
        setLogoutModalVisible(false);
    };

    const handleOpenLogoutModal = (): void => {
        setLogoutModalVisible(true);
    };

    return (
        <div className="button-grid">
            <Button id="logout-button" onClick={handleOpenLogoutModal}>
                <LogoutIcon id="logout-icon" />
            </Button>
            <Modal
                title={content.account.log_out.title}
                visible={logoutModalVisible}
                onOk={handleCancel}
                onCancel={handleLogout}
                className="logout-modal"
                okText={content.account.log_out.cancel}
                okType="default"
                cancelText={content.account.log_out.cta}
            >
                {content.account.log_out.desc}
            </Modal>
        </div>
    );
};

export default function HeaderWithLogo(): ReactElement {
    return (
        <div className="header">
            <Link to="/">
                <Logo id="logo" />
            </Link>
            <Route path="/user/orders" component={OrderPageCta} />
        </div>
    );
}
