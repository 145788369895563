/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import { ReactElement, useContext } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { clinical_protocol as content } from '../../staticContent.json';
import { FormContext } from '.';

const eligibleSymptomsIndicator = [
    content.symptoms.symptom_1,
    content.symptoms.no_symptoms,
];

interface ProtectedRouteProps extends RouteProps {
    Component: React.FunctionComponent<any> | React.ComponentClass<any>;
}

function ProtectedRoute({
    Component,
    ...restOfProps
}: ProtectedRouteProps): ReactElement {
    const form = useContext(FormContext);
    const formValues = form?.getFieldsValue(true);

    const symptoms = formValues?.symptoms || [];

    // Check if symptoms includes anything except 'None of the above" or symptom_1
    const isValid =
        symptoms.length === 0 ||
        (symptoms.length === 1 &&
            eligibleSymptomsIndicator.includes(symptoms[0]));

    return (
        <Route
            {...restOfProps}
            render={(props) =>
                isValid ? (
                    <Component {...props} />
                ) : (
                    <Redirect to="/form/ineligible" />
                )
            }
        />
    );
}

export default ProtectedRoute;
