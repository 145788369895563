import { Form, Input, Typography } from 'antd';
import { ReactElement } from 'react';
import zipValidator from '../../helpers/zipValidator';
import { pharmacy as content } from '../../staticContent.json';
import './pharmacySearch.less';
import InputNumber from '../../components/InputNumber';

const { Item } = Form;
const { Title, Paragraph } = Typography;

export default function PharmacySearch(): ReactElement {
    return (
        <div className="pharmacy-search">
            <Title>{content.title}</Title>
            <Paragraph>{content.desc}</Paragraph>
            <Item
                name="pharmacyZipCode"
                label="Zip code"
                rules={[
                    {
                        required: true,
                        message: 'Please enter a valid number',
                    },
                    zipValidator('pharmacyZipCode'),
                ]}
                hasFeedback
                required
            >
                <InputNumber data-testid="pharmacy-search-test-id" />
            </Item>
            <Item
                className="search-criteria--zip"
                name="pharmacyNameFilter"
                label="Search by name (optional)"
            >
                <Input data-testid="pharmacy-name-select-test-id" />
            </Item>
        </div>
    );
}
