import axios from 'axios';
import { HealthProfile } from './userHealthProfileAdapter';
import { HealthProfiles } from './UserHealthProfiles';

interface Config {
    params: {
        userToken: string;
    };
    headers?: {
        Authorization: string;
    };
}

interface ApiResponse {
    id: 'number';
    userId: 'string';
    patientToken: 'string';
    firstName: 'string';
    lastName: 'string';
    termsConsent: 'boolean';
    informedConsent: 'boolean';
}

export default (token?: string) =>
    async (userToken: string): Promise<HealthProfile[]> => {
        const { REACT_APP_API_URL, NODE_ENV } = process.env;
        const isTest = NODE_ENV === 'test';

        const config: Config = {
            params: {
                userToken,
            },
        };

        if (token) {
            config.headers = {
                Authorization: `Bearer ${token}`,
            };
        }
        const url = `${
            (!isTest && REACT_APP_API_URL) || 'http://localhost:1080'
        }/api/users/health-profiles`;
        try {
            const { data } = await axios.get(url, config);
            return data.map((healthProfile: ApiResponse): HealthProfiles => {
                return {
                    id: healthProfile.id,
                    userId: healthProfile.userId,
                    patientToken: healthProfile.patientToken,
                    firstName: healthProfile.firstName,
                    lastName: healthProfile.lastName,
                    termsConsent: healthProfile.termsConsent,
                    informedConsent: healthProfile.informedConsent,
                };
            });
        } catch {
            return [];
        }
    };
