import { Typography, Button } from 'antd';
import { ReactElement, useState } from 'react';
import { ReactComponent as Logo } from '../../media/logo-white.svg';
import { footer as footerContent } from '../../staticContent.json';
import './footer.less';
import PolicyModal from '../PolicyModal';
import { PolicyType } from '../PolicyModal/PolicyModal';

const { Paragraph } = Typography;

export default function Footer(): ReactElement {
    const [modalVisible, setModalVisible] = useState(false);
    const [modalType, setModalType] = useState<PolicyType>('privacyPolicy');

    const handlePrivacyPolicyClick = (): void => {
        setModalType('privacyPolicy');
        setModalVisible(true);
    };

    const handleTermsOfServiceClick = (): void => {
        setModalType('termsOfService');
        setModalVisible(true);
    };

    return (
        <div className="footer">
            <div className="footer-container">
                <Logo className="logo" viewBox="0 0 149 38" />
                <div className="link-list">
                    <Button type="link" onClick={handleTermsOfServiceClick}>
                        <Paragraph>Terms of service</Paragraph>
                    </Button>
                    <Button type="link" onClick={handlePrivacyPolicyClick}>
                        <Paragraph>Privacy policy</Paragraph>
                    </Button>
                    <Button
                        type="link"
                        href="https://www.truepill.com/notice-of-privacy-practices"
                        target="_blank"
                    >
                        <Paragraph>Notice of privacy practice</Paragraph>
                    </Button>
                </div>
                <Paragraph>{footerContent.desc_2}</Paragraph>
                <Paragraph className="copyright">
                    {footerContent.copyright}
                </Paragraph>
                <PolicyModal
                    visible={modalVisible}
                    setVisible={setModalVisible}
                    policyType={modalType}
                />
            </div>
        </div>
    );
}
