/* eslint-disable react-hooks/exhaustive-deps */
import { ReactElement, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Alert, Button, Typography } from 'antd';
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons';
import axios from 'axios';
import { Consult, getConsult } from './ConsultAdapter';
import consultHook from './ConsultApiInterface';
import htmlToPDF, {
    AppointmentProps,
    ReceiptProps,
} from '../../utils/htmlToPDF';
import centsToDollars from '../../utils/centsToDollar';
import { ReactComponent as ErrorIcon } from '../../media/error.svg';

import { ReactComponent as SummaryIcon } from './summary.svg';
import { ReactComponent as PrescriptionIcon } from './prescription.svg';
import { ReactComponent as ChevronUpIcon } from './chevron-up.svg';
import { ReactComponent as ChevronDownIcon } from './chevron-down.svg';

import './ConsultDetails.less';

const { REACT_APP_API_URL, NODE_ENV } = process.env;
const isTest = NODE_ENV === 'test';
const { Text } = Typography;
enum DownloadOptions {
    SUMMARY = 'summary',
    RECEIPT = 'receipt',
}
interface Props {
    orderId: string;
    consultId: string;
    transferred: boolean;
    patientName: string;
    paymentId: string;
    bookingDateTime: string;
}
interface Config {
    headers?: {
        Authorization: string;
    };
    params?: {
        paymentId: string;
    };
}

export default function ConsultDetails({
    orderId,
    consultId,
    transferred,
    patientName,
    paymentId,
    bookingDateTime,
}: Props): ReactElement {
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [consult, setConsult] = useState<Consult | null>(null);
    const [token, setToken] = useState<string>('');
    const [isDownloadingPDF, setIsDownloadingPDF] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);
    const { user, getAccessTokenSilently } = useAuth0();

    const loadConsult = async (): Promise<void> => {
        setIsLoading(true);
        const newToken = !isTest
            ? await getAccessTokenSilently({
                  audience: process.env.REACT_APP_AUTH0_AUDIENCE,
              })
            : '';
        setToken(newToken);
        const cb = consultHook(newToken);

        const response = await getConsult(cb, consultId);
        // Loading Stripe information
        const stripeUrl = `${
            (!isTest && REACT_APP_API_URL) || 'http://localhost:1080'
        }/api/payment/${paymentId}`;

        const stripeConfig: Config = {};
        if (newToken) {
            stripeConfig.headers = {
                Authorization: `Bearer ${newToken}`,
            };
        }
        // Get Stripe information
        axios
            .get(stripeUrl, stripeConfig)
            .then(({ data }) => {
                setConsult({ ...response, amount: data.amount });
            })
            .catch(() => {
                return null;
            });
        setIsLoading(false);
    };

    useEffect(() => {
        if (user && consultId && isVisible && !consult) {
            loadConsult();
        }
    }, [user, consultId, isVisible, consult, getAccessTokenSilently]);

    const getFriendlyTreatmentName = (
        treatmentIdentifier: string | undefined
    ): string => {
        if (!treatmentIdentifier) {
            return '';
        }
        return treatmentIdentifier === `rx_prescribed`
            ? 'Take prescribed medication'
            : 'Take over the counter medication';
    };

    const onClickDetails = (): void => {
        if (!consult) {
            setIsLoading(true);
        }
        setIsVisible(!isVisible);
    };

    const formatDiagnosis = (diagnosis: string): string => {
        if (diagnosis.includes('Otitis media, unspecified')) {
            return diagnosis.replace(
                'Otitis media, unspecified',
                'Middle Ear Infection'
            );
        }
        return diagnosis.replace(
            'Unspecified otitis externa',
            'Outer Ear Infection'
        );
    };

    const downloadPDF = (props: AppointmentProps | ReceiptProps): void => {
        htmlToPDF(token, props)
            .then(() => setIsDownloadingPDF(false))
            .catch(() => {
                setShowError(true);
                setTimeout(() => {
                    setShowError(false);
                }, 3000);
            });
    };

    const handleDownloadReceipt = async (): Promise<void> => {
        setShowError(false);
        setIsDownloadingPDF(true);
        const receiptProps = {
            type: DownloadOptions.RECEIPT,
            orderId,
            patientName,
            accountHolder: `${user?.given_name} ${user?.family_name}`,
            patientDate: bookingDateTime,
            merchant: 'Truepill Inc',
            consultationPrice: centsToDollars(consult?.amount),
            totalPrice: centsToDollars(consult?.amount),
        };
        await downloadPDF(receiptProps);
    };

    const handleDownloadSummary = async (): Promise<void> => {
        setShowError(false);
        setIsDownloadingPDF(true);
        const medicationPrescribed =
            consult?.treatmentIdentifier[0] &&
            consult?.treatmentIdentifier[0] === 'rx_prescribed';
        const appointmentProps = {
            type: DownloadOptions.SUMMARY,
            patientName,
            patientDate: bookingDateTime,
            patientStatus: (consult?.status || '').toUpperCase(),
            providerName: `${consult?.provider?.firstName} ${consult?.provider?.lastName}`,
            diagnosis: consult?.diagnoses[0]?.description
                ? formatDiagnosis(consult?.diagnoses[0]?.description)
                : '',
            treatment: medicationPrescribed
                ? 'Take prescribed medication'
                : 'Take over the counter medication',
            notes: medicationPrescribed
                ? 'Please avoid getting the ear canal wet (avoid swimming; use a bathing cap or removable cotton ball coated with petroleum jelly while showering). Please also avoid inserting any foreign objects into the ear (fingers, towels, cotton swabs etc). If there is no improvement of current symptoms after 48 hours, please call us back at +1 855-618-0190. If the child develops worsening of current symptoms or persistently rising fevers, vomiting, inability to tolerate oral nutrition/hydration, dizziness, confusion, or severe headache/neck stiffness, please seek urgent/emergent care immediately.'
                : 'At this time, close observation of your child’s symptoms along with over the counter acetaminophen or ibuprofen based on the child’s age/weight to assist with pain control is what is recommended. If there is no improvement of current symptoms after 48 hours of antibiotic use, please call us back at +1 855-618-0190. If there is no improvement of current symptoms after 48 hours, please call us back at +1 855-618-0190. If the child develops worsening of current symptoms or persistently rising fevers, vomiting, inability to tolerate oral nutrition/hydration, dizziness, confusion, or severe headache/neck stiffness, please seek urgent/emergent care immediately.',
            prescription: consult?.prescriptions[0],
            prescriptionStatus: !transferred
                ? 'In Progress'
                : 'Transferred to nominated pharmacy',
        };
        await downloadPDF(appointmentProps);
    };

    const PDFErrorMessage =
        'There was an error loading this document. Please try again';
    if (isLoading) return <LoadingOutlined />;

    if (!isVisible) {
        return (
            <>
                <Button type="link" className="link" onClick={onClickDetails}>
                    Show details
                    <ChevronDownIcon className="chevron-icon" />
                </Button>
            </>
        );
    }

    const pharmacy =
        consult?.status?.toLowerCase() === 'completed' &&
        consult?.prescriptions[0]?.pharmacy;

    return (
        <>
            <Button type="link" className="link" onClick={onClickDetails}>
                Hide details
                <ChevronUpIcon className="chevron-icon" />
            </Button>
            {consult?.status?.toLowerCase() === 'completed' && (
                <>
                    <div className="subheader">
                        <Text strong>
                            <SummaryIcon className="icon" />
                            &nbsp;Summary
                        </Text>
                    </div>
                    <div>
                        <Text strong>Provider: </Text>
                        <Text>
                            {consult?.provider?.firstName}{' '}
                            {consult?.provider?.lastName}
                        </Text>
                    </div>

                    <div>
                        <Text strong>Diagnosis: </Text>
                        <Text>
                            {consult?.diagnoses[0]?.description
                                ? formatDiagnosis(
                                      consult?.diagnoses[0]?.description
                                  )
                                : ''}
                        </Text>
                    </div>
                    <div>
                        <Text strong>Treatment: </Text>
                        <Text>
                            {getFriendlyTreatmentName(
                                consult?.treatmentIdentifier[0]
                            )}
                        </Text>
                    </div>
                </>
            )}
            {consult?.prescriptions &&
                consult?.prescriptions.map((prescription) => {
                    return (
                        <>
                            <div className="subheader">
                                <Text strong>
                                    <PrescriptionIcon className="icon" />
                                    &nbsp;Prescription
                                </Text>
                            </div>
                            <div>
                                <Text strong>Medication: </Text>
                                <Text>{prescription.name}</Text>
                            </div>
                            <div>
                                <Text strong>Directions: </Text>
                                <Text>{prescription.sig}</Text>
                            </div>
                            <div>
                                <Text strong>Status: </Text>
                                <Text>
                                    {!transferred
                                        ? 'In Progress'
                                        : 'Transferred to nominated pharmacy'}
                                </Text>
                            </div>
                            {pharmacy && (
                                <div>
                                    <Text strong>Selected pharmacy: </Text>
                                    <Text>{`${pharmacy.name}, ${pharmacy.address.street1}, ${pharmacy.address.city}, ${pharmacy.address.state} ${pharmacy.address.zip}`}</Text>
                                </div>
                            )}
                        </>
                    );
                })}
            <div>
                <hr />
                {consult?.status?.toLowerCase() === 'completed' && (
                    <>
                        <Button
                            type="link"
                            className="link"
                            onClick={() => handleDownloadSummary()}
                            disabled={!consult || isDownloadingPDF}
                            icon={<DownloadOutlined />}
                        >
                            Download summary as a PDF
                        </Button>
                        <hr />
                    </>
                )}
                <Button
                    type="link"
                    className="link"
                    onClick={() => handleDownloadReceipt()}
                    disabled={!consult || isDownloadingPDF}
                    icon={<DownloadOutlined />}
                >
                    Download receipt
                </Button>
            </div>
            {showError && (
                <Alert
                    className="alert-error"
                    type="error"
                    message={PDFErrorMessage}
                    onClose={() => setShowError(false)}
                    icon={<ErrorIcon />}
                    showIcon
                    closable
                />
            )}
        </>
    );
}
