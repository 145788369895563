export interface HealthProfile {
    id: 'number';
    userId: 'string';
    patientToken: 'string';
    firstName: 'string';
    lastName: 'string';
    termsConsent: 'boolean';
    informedConsent: 'boolean';
}

type UserHealthProfilesCallback = (userId: string) => Promise<HealthProfile[]>;

export const getUserHealthProfiles = async (
    cb: UserHealthProfilesCallback,
    userToken: string
): Promise<HealthProfile[]> => {
    const userHealthProfiles = await cb(userToken);
    return userHealthProfiles;
};
