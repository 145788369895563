export interface Address {
    city: string;
    state: string;
    street1: string;
    zip: string;
}

export interface Pharmacy {
    address: Address;
    name: string;
}

export interface Prescription {
    id: string;
    ndc: string;
    name: string;
    strength: string;
    daysSupply: string;
    refills: number;
    sig: string;
    quantity: number;
    createdAt: Date;
    pharmacy: Pharmacy;
}

export interface Appointment {
    duration: number;
    apptDate: string;
    start: string;
    end: string;
}

export interface Provider {
    npi: number;
    firstName: string;
    lastName: string;
}

export interface Patient {
    id: string;
    firstName: string;
    lastName: string;
    patientToken: string;
}

export interface Diagnoses {
    code: string;
    description: string;
    version: string;
}

export interface Consult {
    id: string;
    consultId: string;
    status?: string;
    appointment: Appointment;
    prescriptions: Prescription[];
    patient?: Patient;
    provider?: Provider;
    amount?: number;
    diagnoses: Diagnoses[];
    treatmentIdentifier: string[];
}

type ConsultCallback = (consultId: string) => Promise<Consult>;

export const getConsult = async (
    cb: ConsultCallback,
    consultId: string
): Promise<Consult> => {
    const consult = await cb(consultId);
    return consult;
};
