import axios from 'axios';
import { Consult, Pharmacy } from './ConsultAdapter';

interface Config {
    headers?: {
        Authorization: string;
    };
}

interface ApiPrescription {
    id: string;
    ndc: string;
    name: string;
    strength: string;
    // eslint-disable-next-line camelcase
    days_supply: string;
    refills: number;
    sig: string;
    pharmacy: Pharmacy;
    quantity: number;
    // eslint-disable-next-line camelcase
    created_at: Date;
}

export default (token?: string) =>
    async (consultId: string): Promise<Consult> => {
        const { REACT_APP_API_URL, NODE_ENV } = process.env;
        const isTest = NODE_ENV === 'test';

        const config: Config = {};

        if (token) {
            config.headers = {
                Authorization: `Bearer ${token}`,
            };
        }
        const url = `${
            (!isTest && REACT_APP_API_URL) || 'http://localhost:1080'
        }/api/consult/${consultId}`;
        const { data } = await axios.get(url, config);

        return {
            id: data.id,
            consultId: data.consultId,
            status: data.status,
            appointment: data.appointment
                ? {
                      duration: data.appointment.duration,
                      apptDate: data.appointment.apptDate,
                      start: data.appointment.start,
                      end: data.appointment.end,
                  }
                : null,
            provider: data.provider
                ? {
                      npi: data.provider.npi,
                      firstName: data.provider.first_name,
                      lastName: data.provider.last_name,
                  }
                : null,
            prescriptions: data.prescriptions.map(
                (prescription: ApiPrescription) => {
                    return {
                        id: prescription.id,
                        ndc: prescription.ndc,
                        name: prescription.name,
                        strength: prescription.strength,
                        daysSupply: prescription.days_supply,
                        refills: prescription.refills,
                        sig: prescription.sig,
                        pharmacy: prescription.pharmacy,
                        quantity: prescription.quantity,
                        createdAt: prescription.created_at,
                    };
                }
            ),
            patient: data.patient
                ? {
                      id: data.patient.id,
                      firstName: data.patient.first_name,
                      lastName: data.patient.last_name,
                      patientToken: data.patient.patient_token,
                  }
                : null,
            treatmentIdentifier: data.treatment_identifier,
            diagnoses: data.diagnoses,
        } as Consult;
    };
