export interface Address {
    street1: 'string';
    street2: 'string';
    city: 'string';
    state: 'string';
    zip: 'number';
}

export interface Patient {
    gender: string;
    day: number;
    month: number;
    year: number;
    address: Address;
}

type PatientCallback = (patientToken: string) => Promise<Patient>;

export const getPatient = async (
    cb: PatientCallback,
    patientToken: string
): Promise<Patient> => {
    const patient = await cb(patientToken);
    return patient;
};
