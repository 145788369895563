import { ReactElement } from 'react';
import { Switch, Route } from 'react-router-dom';
import ExistingUserRouter from './components/ExistingUserRouter';
import Form from './pages/Form';
import Home from './pages/Home/Home';
import Inactivity from './pages/Inactivity';

export default function Router(): ReactElement {
    return (
        <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/form" component={Form} />
            <Route path="/user" component={ExistingUserRouter} />
            <Route path="/inactivity" component={Inactivity} />
        </Switch>
    );
}
