import { LoadingOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { ReactElement, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import './userLogin.less';

export default function UserLogin(): ReactElement {
    const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
    const history = useHistory();

    useEffect(() => {
        if (!isLoading && !isAuthenticated) {
            loginWithRedirect({ mode: 'login' });
        }
        if (!isLoading && isAuthenticated) {
            history.push('/user/orders');
        }
    }, [isLoading, isAuthenticated, loginWithRedirect, history]);

    return (
        <div className="spin-container">
            <LoadingOutlined />
        </div>
    );
}
