/* eslint-disable react/no-danger */
import { ReactElement, useEffect } from 'react';
import { Button, Col, Row, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import Interweave from 'interweave';
import { home as homeContent } from '../../staticContent.json';
import Header from '../../components/HeaderWithLogo';
import Footer from '../../components/Footer';
import mixpanel, { MixpanelLabels } from '../../utils/mixpanel';
import './home.less';

const { Title, Paragraph } = Typography;

export default function Home(): ReactElement {
    function handleClick(): void {
        window.location.href =
            'https://apps.apple.com/us/app/smartcheck-digital-ear-scope/id1588435420';
    }

    useEffect(() => {
        mixpanel.track(MixpanelLabels.LANDING_PAGE);
    }, []);

    return (
        <div className="home">
            <Header />
            <Row align="middle" className="hide-x-overflow">
                <Col id="container-1" xl={10} lg={24}>
                    <Title id="container-1__headline">
                        {homeContent.section_1.headline}
                    </Title>
                    <Paragraph className="landing-paragraph">
                        <Interweave
                            content={homeContent.section_1.subheadline_1}
                        />
                    </Paragraph>
                    <Button
                        id="container-1__cta"
                        type="primary"
                        className="light"
                        onClick={() => handleClick()}
                    >
                        {homeContent.consult_cta}
                    </Button>
                </Col>
                <Col id="container-1__div__img" xl={12} lg={24}>
                    <picture id="container-1__img">
                        <source
                            srcSet="/images/home_1.webp"
                            type="image/webp"
                        />
                        <source srcSet="/images/home_1.jpg" type="image/jpeg" />
                        <img
                            src="/images/home_1.jpg"
                            alt="Woman hugging her child"
                        />
                    </picture>
                </Col>
            </Row>
            <Footer />
        </div>
    );
}
