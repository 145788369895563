/* eslint-disable @typescript-eslint/no-unused-vars */
import { PropsWithChildren, ReactElement } from 'react';

interface Props {
    path: string;
    noNext?: boolean;
    oneTime?: boolean;
    skip?: boolean;
    finalStep?: boolean;
}

const Step = ({
    path,
    children,
    noNext,
    oneTime,
    skip,
    finalStep,
}: PropsWithChildren<Props>): ReactElement => {
    return <>{children}</>;
};

Step.defaultProps = {
    noNext: false,
    oneTime: false,
    skip: false,
    finalStep: false,
};

export default Step;
