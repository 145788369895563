import axios, { ResponseType } from 'axios';
import { Prescription } from '../components/ConsultDetails/ConsultAdapter';

interface RequestConfig {
    responseType?: ResponseType;
    headers: {
        Authorization?: string;
        Accept?: string;
        'Content-Type'?: string;
        'x-goog-content-length-range'?: string;
    };
    timeout?: number;
}

enum DownloadOptions {
    SUMMARY = 'summary',
    RECEIPT = 'receipt',
}
export interface AppointmentProps {
    type: string;
    patientName: string;
    patientDate: string;
    patientStatus: string;
    providerName: string;
    diagnosis: string;
    treatment: string;
    notes: string;
    prescription?: Prescription;
    prescriptionStatus: string;
}

export interface ReceiptProps {
    type: string;
    orderId: string;
    patientName: string;
    accountHolder: string;
    patientDate: string;
    merchant: string;
    consultationPrice?: string;
    totalPrice?: string;
}

const { REACT_APP_API_URL } = process.env;

const config = (token: string): RequestConfig => {
    return {
        responseType: 'arraybuffer',
        headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'text/plain',
            Accept: 'application/pdf',
        },
    };
};

const downloadFileName = (
    dataDict: AppointmentProps | ReceiptProps
): string => {
    const { type, patientName, patientDate } = dataDict;
    return type === DownloadOptions.RECEIPT
        ? `Receipt ${patientName} ${patientDate}.pdf`
        : `Summary ${patientName} ${patientDate}.pdf`;
};

const htmlToPDF = async (
    token: string,
    dataDict: AppointmentProps | ReceiptProps
): Promise<void> => {
    return axios
        .post(`${REACT_APP_API_URL}/api/consult/pdf`, dataDict, config(token))
        .then(({ data }) => {
            const file = new Blob([data], { type: 'application/pdf' });
            const url = window.URL.createObjectURL(file);
            const link = document.createElement('a');
            link.href = url;
            link.download = downloadFileName(dataDict);
            link.click();
        });
};
export default htmlToPDF;
