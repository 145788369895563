import { useAuth0 } from '@auth0/auth0-react';
import { ReactElement } from 'react';
import { Button, Col, Row, Typography } from 'antd';
import { inactivity as content } from '../../staticContent.json';
import Header from '../../components/HeaderWithLogo';
import Auth0Wrapper from '../../components/Auth0Wrapper';

import './Inactivity.less';

const { Title, Paragraph } = Typography;

const Inactivity = (): ReactElement => {
    const { logout } = useAuth0();
    return (
        <>
            <Header />
            <Row className="wrapper" align="middle">
                <Col xs={0} md={4} lg={8} />
                <Col xs={24} md={16} lg={8}>
                    <div className="inactivity-container">
                        <Title className="title">{content.heading}</Title>
                        <Paragraph className="subTitle">
                            {content.subHeading}
                        </Paragraph>
                        <Button
                            className="button"
                            type="primary"
                            onClick={() =>
                                logout({
                                    returnTo: `${window.location.origin}/user`,
                                })
                            }
                        >
                            {content.buttonLogin}
                        </Button>
                    </div>
                </Col>
                <Col xs={0} md={4} lg={8} />
            </Row>
        </>
    );
};

export default Auth0Wrapper(Inactivity, window.location.origin);
