import mixpanel, { Dict } from 'mixpanel-browser';

const token = process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN || '';

mixpanel.init(token);

const isProduction = process.env.REACT_APP_MIXPANEL_ACTIVE === 'true';

export const MixpanelLabels = {
    LANDING_PAGE: 'Landing Page',
    START_CONSULTATION: 'Start a New Consultation',
    EXISTING_ACCOUNT_YES: 'Existing account - yes',
    EXISTING_ACCOUNT_NO: 'Existing account - no',
    HEALTH_PROFILE_COMPLETION: 'Health Profile Completion',
    URAC_COMPLETION: 'URAC Completion',
    CLINICAL_COMPLETION: 'Clinical Protocol Completion',
    ACCOUNT_CREATION: 'Account Creation',
    LEFT_EAR_VIDEO: 'Video upload (1st ear)',
    RIGHT_EAR_VIDEO: 'Video upload (2nd ear)',
    PHARMACY_SELECT: 'Pharmacy Select',
    CHECKOUT: 'Checkout',
    ORDER_CONFIRMATION: 'Order Confirmation',
    JOIN_VIDEO_CONSULTATION: 'Join Consultation',
    INELIGIBLE_PAGE: 'Ineligible Page',
};

export default {
    identify: (id: string): void => {
        if (isProduction) mixpanel.identify(id);
    },
    alias: (id: string): void => {
        if (isProduction) mixpanel.alias(id);
    },
    track: (name: string, props?: Dict): void => {
        if (isProduction) mixpanel.track(name, props);
    },
    people: {
        set: (props: Dict): void => {
            if (isProduction) mixpanel.people.set(props);
        },
    },
};
