import { ReactElement, useState, useContext, useEffect } from 'react';
import { Col, Row, Typography, Form, Checkbox, Button } from 'antd';
import { compareAsc, parse, addYears, format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { LoadingOutlined } from '@ant-design/icons';
import UracCard from '../../components/UracCard';
import { urac as content } from '../../staticContent.json';
import mixpanel, { MixpanelLabels } from '../../utils/mixpanel';

import { FormContext } from '../../components/Stepper';
import { getPatientName } from '../../utils/textUtils';
import { getUserUrac } from './userUracAdapter';
import userUracHook from './userUracApiInterface';
import { setWithExpiry } from '../../helpers/localStorageWithExpiry';

import './URAC.less';

const { Item } = Form;
const { Title, Paragraph } = Typography;

interface QuestionsInterface {
    cardLabel: string;
    inputLabel: string;
    prompt: string;
    name: string;
    title: string;
}
const questions: QuestionsInterface[] = [
    {
        cardLabel: content.allergies.title,
        inputLabel: `${content.allergies.prompt_1} *`,
        prompt: content.allergies.prompt_2,
        name: 'listAllergies',
        title: 'Allergies',
    },
    {
        cardLabel: content.health_conditions.title,
        inputLabel: `${content.health_conditions.prompt_1} *`,
        prompt: content.health_conditions.prompt_2,
        name: 'listHealthConditions',
        title: 'Health conditions',
    },
    {
        cardLabel: content.medications.title,
        inputLabel: `${content.medications.prompt_1} *`,
        prompt: content.medications.prompt_2,
        name: 'listMedications',
        title: 'Medications',
    },
];

export default (): ReactElement => {
    const history = useHistory();
    const form = useContext(FormContext);
    const { user, getAccessTokenSilently } = useAuth0();
    const [currentQuestion, setCurrentQuestion] = useState<number>(0);
    const [answers, setAnswers] = useState<string[][]>(Array(questions.length));
    const formData = form?.getFieldsValue(true);
    const [isLoading, setIsLoading] = useState(false);
    const [lastUpdated, setLastUpdated] = useState<string>('');
    const [upToDate, setUpToDate] = useState<boolean>(false);

    const patientToken = formData && formData.patientToken;

    const [isDisable, setIsDisabled] = useState<boolean>(false);

    useEffect(() => {
        const setUracResponses = async (): Promise<void> => {
            const isTest = process.env.NODE_ENV === 'test';
            if (!isTest && user?.sub && patientToken) {
                setIsLoading(true);

                const token = await getAccessTokenSilently({
                    audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                });
                const cb = userUracHook(token);

                const userUrac = await getUserUrac(cb, patientToken);
                if (userUrac) {
                    form?.setFieldsValue({ urac: userUrac });

                    setLastUpdated(userUrac.updatedAt);
                }
                setIsLoading(false);
            }
        };

        setUracResponses();
    }, [getAccessTokenSilently, user, form, patientToken]);

    useEffect(() => {
        if (formData && formData.urac) {
            let questionsAnswered = 0;

            const initialAnswers = questions.map(({ name }) => {
                const { urac } = formData;
                const formValue = urac[name];
                if (!formValue) return undefined;
                questionsAnswered += 1;
                if (formValue === 'N/A') return [];
                return formValue.split(', ');
            });

            setAnswers(initialAnswers);

            setCurrentQuestion(questionsAnswered);
        }
    }, [formData]);

    useEffect(() => {
        // If we have no patient token then it's a brand new user, we only want to check if they're past step 2
        // If they're an existing user then we need past step 2 AND checkbox marked up to date
        // Special note: if you edit a question it will change the current question equal to that index which will grey out the button
        // but upon hitting 'save' it will jump back to index 3 which makes this function
        if (
            (!patientToken && currentQuestion > 2) ||
            (patientToken && currentQuestion > 2 && upToDate)
        ) {
            setIsDisabled(true);
        } else {
            setIsDisabled(false);
        }
    }, [currentQuestion, upToDate, patientToken]);

    const onSave = (answer: string[]): void => {
        const newAnswers = [...answers];
        newAnswers[currentQuestion] = answer;
        setAnswers(newAnswers);
        const questionKey = questions[currentQuestion].name;
        const answerString = answer.join(', ') || 'N/A';

        form?.setFieldsValue({
            urac: { ...formData.urac, [questionKey]: answerString },
        });

        setCurrentQuestion(currentQuestion + 1);
    };

    const isFifteenOrOver = (): boolean => {
        const { day, month, year } = formData;
        const dob = parse(`${day}-${month}-${year}`, 'dd-MM-yyyy', new Date());
        const today = new Date();
        const fifteenYearsAgo = addYears(today, -15);
        return compareAsc(fifteenYearsAgo, dob) >= 0;
    };

    const handleClick = async (): Promise<void> => {
        await form?.validateFields();
        const data = form?.getFieldsValue(true);
        const hasErrors = form
            ?.getFieldsError()
            .some(({ errors }) => errors.length);

        if (!hasErrors && (!patientToken || !!data.upToDate)) {
            setWithExpiry('formData', JSON.stringify(data), 20 * 60 * 1000);

            mixpanel.track(MixpanelLabels.URAC_COMPLETION);

            history.push('/form/informed-consent');
        }
    };

    if (isLoading) return <LoadingOutlined />;

    return (
        <>
            <Title>
                {user?.sub && formData.urac && lastUpdated
                    ? content.exising_title
                    : content.title}
            </Title>
            {user?.sub && formData.urac && lastUpdated && (
                <Paragraph>{content.existing_instructions}</Paragraph>
            )}
            {user?.sub && formData.urac && lastUpdated && (
                <Row>
                    <Col>
                        <Paragraph strong>
                            {content.existing_last_updated}
                        </Paragraph>
                    </Col>
                    <Col flex={1} style={{ marginLeft: '4px' }}>
                        {format(new Date(lastUpdated), 'dd/MM/yy')}
                    </Col>
                </Row>
            )}
            {questions.map(
                ({ cardLabel, inputLabel, name, prompt, title }, index) => (
                    <UracCard
                        key={name}
                        cardLabel={getPatientName(
                            cardLabel,
                            formData.firstName
                        )}
                        cardTitle={title}
                        inputLabel={inputLabel}
                        name={name}
                        prompt={prompt}
                        active={index === currentQuestion}
                        onSave={onSave}
                        saved={answers[index] !== undefined}
                        currentAnswers={answers[index]}
                        setCurrentQuestion={() => setCurrentQuestion(index)}
                    />
                )
            )}
            {formData?.gender === 'female' && isFifteenOrOver() && (
                <Paragraph>{content.pregnancy}</Paragraph>
            )}
            {patientToken && (
                <Item
                    name="upToDate"
                    className="upToDate"
                    valuePropName="checked"
                >
                    <Checkbox onChange={(e) => setUpToDate(e.target.checked)}>
                        {content.existing_checkbox}
                    </Checkbox>
                </Item>
            )}
            <Button
                type="primary"
                block
                onClick={() => handleClick()}
                className="primary-form-cta"
                disabled={!isDisable}
            >
                {content.continue_button}
            </Button>
        </>
    );
};
