import {
    Dispatch,
    ReactElement,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useState,
} from 'react';
import { Col, Form, Input, Row, Typography } from 'antd';
import { isExists, isWithinInterval, sub } from 'date-fns';
import InputNumber from '../../components/InputNumber/InputNumber';
import { profile_new as newProfileContent } from '../../staticContent.json';
import { FormContext } from '../../components/Stepper';

const content = newProfileContent.form;

const { Item } = Form;

type ValidateStatus =
    | ''
    | 'success'
    | 'warning'
    | 'error'
    | 'validating'
    | undefined;

export default (): ReactElement => {
    const [isComplete, setIsComplete] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);
    const form = useContext(FormContext);
    const [dayValidateStatus, setDayValidateStatus] =
        useState<ValidateStatus>('');
    const [monthValidateStatus, setMonthValidateStatus] =
        useState<ValidateStatus>('');
    const [yearValidateStatus, setYearValidateStatus] =
        useState<ValidateStatus>('');
    const [dateValidateStatus, setDateValidateStatus] =
        useState<ValidateStatus>('');
    const [ageValidateStatus, setAgeValidateStatus] =
        useState<ValidateStatus>('');

    const [errorMessage, setErrorMessage] = useState<string>('');

    const formData = form?.getFieldsValue(true);

    const [month, setMonth] = useState('');
    const [day, setDay] = useState('');
    const [year, setYear] = useState('');

    const dateValidator = useCallback(
        (defaultDay = '', defaultMonth = '', defaultYear = '') => {
            const usedDay = day || defaultDay;
            const usedMonth = month || defaultMonth;
            const usedYear = year || defaultYear;
            const allFieldTouched = usedDay && usedMonth && usedYear;
            const numberYear = Number(usedYear);
            const numberMonth = Number(usedMonth) - 1;
            const numberDay = Number(usedDay);
            const isValidDate = isExists(numberYear, numberMonth, numberDay);

            setDateValidateStatus(
                allFieldTouched && !isValidDate ? 'error' : undefined
            );
        },
        [day, month, year]
    );

    const ageValidator = useCallback(
        (defaultDay = '', defaultMonth = '', defaultYear = '') => {
            const usedDay = day || defaultDay;
            const usedMonth = month || defaultMonth;
            const usedYear = year || defaultYear;
            const allFieldTouched = usedDay && usedMonth && usedYear;
            const numberYear = Number(usedYear);
            const numberMonth = Number(usedMonth) - 1;
            const numberDay = Number(usedDay);
            const sixMonthsAgo = sub(new Date(), { months: 6 });
            const seventeenYearsAgo = sub(new Date(), { years: 17 });

            const isBetweenRange = isWithinInterval(
                new Date(numberYear, numberMonth, numberDay),
                {
                    end: sixMonthsAgo,
                    start: seventeenYearsAgo,
                }
            );

            setAgeValidateStatus(
                allFieldTouched && !isBetweenRange ? 'error' : undefined
            );
        },
        [day, month, year]
    );

    const monthValidator = useCallback(
        (defaultMonth?: string, first?: boolean): void => {
            const usedMonth = month || defaultMonth;
            const numberMonth = Number(usedMonth);
            const isValidMonth = numberMonth >= 1 && numberMonth <= 12;
            if (!usedMonth && first) {
                setMonthValidateStatus('');
            } else if (isValidMonth) {
                setMonthValidateStatus('success');
                form?.setFieldsValue({
                    month: numberMonth.toLocaleString('en-US', {
                        minimumIntegerDigits: 2,
                    }),
                });
            } else {
                setMonthValidateStatus('error');
            }
            dateValidator();
            ageValidator();
        },
        [ageValidator, dateValidator, form, month]
    );

    const dayValidator = useCallback(
        (defaultDay?: string, first?): void => {
            const usedDay = day || defaultDay;
            const numberDay = Number(usedDay);
            const isValidDay = numberDay >= 1 && numberDay <= 31;
            if (!usedDay && first) {
                setDayValidateStatus('');
            } else if (isValidDay) {
                setDayValidateStatus('success');
                form?.setFieldsValue({
                    day: numberDay.toLocaleString('en-US', {
                        minimumIntegerDigits: 2,
                    }),
                });
            } else {
                setDayValidateStatus('error');
            }
            dateValidator();
            ageValidator();
        },
        [ageValidator, dateValidator, day, form]
    );

    const yearValidator = useCallback(
        (defaultYear?: string, first?): void => {
            const usedYear = year || defaultYear;
            const numberYear = Number(usedYear);

            const isValidYear = numberYear >= 1000;
            if (!usedYear && first) {
                setYearValidateStatus('');
            } else if (isValidYear) {
                setYearValidateStatus('success');
            } else {
                setYearValidateStatus('error');
            }
            dateValidator();
            ageValidator();
        },
        [ageValidator, dateValidator, year]
    );

    useEffect(() => {
        if (firstLoad) {
            setMonth(formData?.month);
            setDay(formData?.day);
            setYear(formData?.year);

            monthValidator(formData?.month, true);
            dayValidator(formData?.day, true);
            yearValidator(formData?.year, true);
            dateValidator(formData?.day, formData?.month, formData?.year);
            ageValidator(formData?.day, formData?.month, formData?.year);
            setFirstLoad(false);
        }
    }, [
        ageValidator,
        dateValidator,
        dayValidator,
        firstLoad,
        formData,
        monthValidator,
        yearValidator,
    ]);

    useEffect(() => {
        if (monthValidateStatus === 'error') {
            return setErrorMessage('Please enter a valid month');
        }
        if (dayValidateStatus === 'error') {
            return setErrorMessage('Please enter a valid day');
        }
        if (yearValidateStatus === 'error') {
            return setErrorMessage('Please enter a valid year');
        }
        if (dateValidateStatus === 'error') {
            return setErrorMessage('Please enter a valid date');
        }
        if (ageValidateStatus === 'error') {
            return setErrorMessage(content.dob_error);
        }

        return setErrorMessage('');
    }, [
        ageValidateStatus,
        dateValidateStatus,
        day,
        dayValidateStatus,
        form,
        month,
        monthValidateStatus,
        year,
        yearValidateStatus,
    ]);

    useEffect(() => {
        setIsComplete(
            dayValidateStatus === 'success' &&
                monthValidateStatus === 'success' &&
                yearValidateStatus === 'success' &&
                dateValidateStatus !== 'error' &&
                ageValidateStatus !== 'error'
        );
    }, [
        ageValidateStatus,
        dateValidateStatus,
        dayValidateStatus,
        monthValidateStatus,
        yearValidateStatus,
    ]);

    const updateField =
        (callback: Dispatch<SetStateAction<string>>) =>
        (event: { target: { value: SetStateAction<string> } }) => {
            callback(event.target.value);
        };

    return (
        <Item required label="Date of birth" className="no-gutter-bottom">
            <Typography className="form-help-text">
                {content.dob_desc}
            </Typography>
            <Item
                label={content.dob_label}
                className="item-group hidden-label"
                validateStatus={dateValidateStatus || ageValidateStatus}
            >
                <Row gutter={6}>
                    <Col span={8}>
                        <Item
                            name="month"
                            label="Month"
                            className="sub-item-label"
                            hasFeedback
                            validateStatus={monthValidateStatus}
                        >
                            <InputNumber
                                data-testid="dob-month"
                                value={month}
                                onChange={updateField(setMonth)}
                                onBlur={() => monthValidator()}
                                placeholder="01"
                            />
                        </Item>
                    </Col>

                    <Col span={8}>
                        <Item
                            name="day"
                            label="Day"
                            className="sub-item-label"
                            hasFeedback
                            validateStatus={dayValidateStatus}
                        >
                            <InputNumber
                                data-testid="dob-day"
                                value={day}
                                onChange={updateField(setDay)}
                                onBlur={() => dayValidator()}
                                placeholder="01"
                            />
                        </Item>
                    </Col>

                    <Col span={8}>
                        <Item
                            name="year"
                            label="Year"
                            className="sub-item-label"
                            hasFeedback
                            validateStatus={yearValidateStatus}
                        >
                            <InputNumber
                                data-testid="dob-year"
                                value={year}
                                onChange={updateField(setYear)}
                                onBlur={() => yearValidator()}
                                placeholder="2020"
                            />
                        </Item>
                    </Col>
                </Row>
                <Row>
                    <Typography className="form-error-text">
                        {errorMessage}
                    </Typography>
                </Row>
            </Item>
            {!isComplete && (
                <Item
                    name="dob"
                    rules={[
                        {
                            required: true,
                            message: '',
                        },
                        () => ({
                            validator() {
                                monthValidator();
                                dayValidator();
                                yearValidator();
                                dateValidator();
                                ageValidator();
                            },
                        }),
                    ]}
                    className="hidden-input"
                >
                    <Input type="hidden" />
                </Item>
            )}
        </Item>
    );
};
