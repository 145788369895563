export type Urac = {
    patientToken: string;
    doYouHaveAnyAllergies: 'yes' | 'no';
    listAllergies?: string;
    healthConditions: 'yes' | 'no';
    listHealthConditions?: string;
    medicationHistory: 'yes' | 'no';
    listMedications?: string;
    updatedAt: string;
};

type UserUracCallback = (userId: string) => Promise<Urac>;

export const getUserUrac = async (
    cb: UserUracCallback,
    userId: string
): Promise<Urac> => {
    const userUrac = await cb(userId);
    return userUrac;
};
