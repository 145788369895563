import { ReactElement, useState, useRef, useEffect } from 'react';
import { Form, Input, Tag, Button, Typography } from 'antd';
import { CheckCircleFilled, PlusOutlined } from '@ant-design/icons';
import './uracCard.less';
import { RadioButton, RadioGroup } from '../HorizontalRadio';

const { Item } = Form;

interface Props {
    cardLabel: string;
    inputLabel: string;
    cardTitle: string;
    name: string;
    active: boolean;
    onSave: (answer: string[]) => void;
    saved: boolean;
    currentAnswers: string[];
    prompt: string;
    setCurrentQuestion: () => void;
}

const Label = ({
    saved,
    setCurrentQuestion,
    label,
    savedLabel,
}: {
    setCurrentQuestion: () => void;
    label: string;
    savedLabel: string;
    saved: boolean;
}): ReactElement => {
    return (
        <div className="heading-container full-width">
            <div className="container" aria-hidden={!saved}>
                <CheckCircleFilled className="urac-checked" />
            </div>
            <div className="text-container">
                <div className="container" aria-hidden={!saved}>
                    <Typography>{savedLabel}</Typography>
                </div>
                <div className="container" aria-hidden={saved}>
                    <Typography>{label}</Typography>
                </div>
            </div>

            <div className="container" aria-hidden={!saved}>
                <Button
                    type="text"
                    className="urac-edit-button"
                    onClick={setCurrentQuestion}
                >
                    Edit
                </Button>
            </div>
        </div>
    );
};

export default function UracCard({
    cardLabel,
    inputLabel,
    cardTitle,
    prompt,
    name,
    onSave,
    currentAnswers,
    active,
    saved,
    setCurrentQuestion,
}: Props): ReactElement {
    const initialChecked = (): undefined | boolean => {
        if (!currentAnswers) return undefined;
        return !!currentAnswers.length;
    };
    const [checked, setChecked] = useState(initialChecked());
    const [values, setValues] = useState<string[]>(currentAnswers || []);
    const [value, setValue] = useState<string>('');
    const collapsed = !saved && !active;

    const addValue = (e: { preventDefault: () => void }): void => {
        e.preventDefault();
        if (value) {
            setValues([...values, value]);
            setValue('');
        }
    };

    const inputContainerRef = useRef<HTMLDivElement>(null);
    const buttonContainerRef = useRef<HTMLDivElement>(null);
    const savedContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (inputContainerRef.current) {
            inputContainerRef.current.style.setProperty(
                '--max-height',
                `${inputContainerRef.current.scrollHeight}px`
            );
        }
    }, [values]);

    useEffect(() => {
        if (savedContainerRef.current) {
            savedContainerRef.current.style.setProperty(
                '--max-height',
                `${savedContainerRef.current.scrollHeight}px`
            );
        }
    }, [saved, active]);

    useEffect(() => {
        if (buttonContainerRef.current) {
            buttonContainerRef.current.style.setProperty(
                '--max-height',
                `${buttonContainerRef.current.scrollHeight}px`
            );
        }
    }, [checked]);

    useEffect(() => {
        if (!checked) {
            setValues([]);
        }
    }, [checked]);

    const isSavedState = saved && !active;
    return (
        <Item
            label={
                <Label
                    saved={isSavedState}
                    savedLabel={cardTitle}
                    label={cardLabel}
                    setCurrentQuestion={setCurrentQuestion}
                />
            }
            className="urac-card"
            data-active={active}
            data-collapsed={collapsed}
            data-saved={saved && !active}
            data-testid={name}
        >
            <div className="urac-card-container">
                <div className="container" aria-hidden={!active}>
                    <Item>
                        <RadioGroup
                            buttonStyle="solid"
                            onChange={(e) => setChecked(e.target.value)}
                            value={checked}
                            className="urac-radio-group"
                        >
                            <RadioButton value className="urac-radio">
                                Yes
                            </RadioButton>
                            <RadioButton value={false} className="urac-radio">
                                No
                            </RadioButton>
                        </RadioGroup>
                    </Item>
                    <div
                        className="container"
                        aria-hidden={!checked}
                        ref={inputContainerRef}
                    >
                        <Item
                            label={inputLabel}
                            data-testid={`${name}-test-id`}
                            className="urac-multi-input"
                        >
                            <Typography className="urac-sub-header">
                                {prompt}
                            </Typography>
                            <Input
                                id={`form_${name}`}
                                value={value}
                                onChange={(e) => setValue(e.target.value)}
                                suffix={
                                    <Button
                                        className="input-button"
                                        ghost
                                        icon={<PlusOutlined />}
                                        onClick={addValue}
                                    />
                                }
                                onPressEnter={addValue}
                            />
                            <div className="urac-tag-container">
                                {values.map((currValue, index) => (
                                    <Tag
                                        className="urac-tag"
                                        closable
                                        onClose={(e) => {
                                            e.preventDefault();
                                            setValues(
                                                values.filter(
                                                    (_, i) => i !== index
                                                )
                                            );
                                        }}
                                        key={`${name}-tag-${currValue}`}
                                    >
                                        <Typography.Text ellipsis>
                                            {currValue}
                                        </Typography.Text>
                                    </Tag>
                                ))}
                                <div className="grow" />
                            </div>
                        </Item>
                    </div>
                    {active && (
                        <Item
                            name={`${name}-urac-input`}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please answer this question',
                                },
                            ]}
                        >
                            <Input type="hidden" />
                        </Item>
                    )}

                    <div
                        aria-hidden={typeof checked === 'undefined'}
                        ref={buttonContainerRef}
                        className="container save-container"
                    >
                        <Button
                            type="primary"
                            className="urac-save-button"
                            onClick={() => onSave(values)}
                            disabled={checked && values.length === 0}
                        >
                            Save
                        </Button>
                    </div>
                </div>
                <div
                    className="container saved-answers"
                    aria-hidden={!(saved && !active)}
                    ref={savedContainerRef}
                >
                    <Typography>
                        <strong>
                            {`${currentAnswers?.length > 0 ? 'Yes - ' : 'No'} `}
                        </strong>
                        {`${currentAnswers?.join(', ')}`}
                    </Typography>
                </div>
            </div>
        </Item>
    );
}
