import { ReactElement, useState } from 'react';
import { Button } from 'antd';
import replace from 'react-string-replace';
import PolicyModal, { PolicyType } from '../components/PolicyModal/PolicyModal';

export function getPatientName(content: string, name: string): string {
    if (content && content.includes('[PATIENT_NAME]')) {
        const removePatient = content.split('[PATIENT_NAME]');
        return `${removePatient[0]}${name}${removePatient[1]}`;
    }
    return content;
}

export function getUsersName(content: string, name: string): string {
    if (content && content.includes('[USER_NAME]')) {
        const removeUser = content.split('[USER_NAME]');
        return `${removeUser[0]}${name}${removeUser[1]}`;
    }
    return content;
}

export function PolicyModals({
    children,
}: {
    children: string | string[];
}): ReactElement {
    const [modalVisible, setModalVisible] = useState(false);
    const [modalType, setModalType] = useState<PolicyType>('privacyPolicy');

    const handlePrivacyPolicyClick = (): void => {
        setModalType('privacyPolicy');
        setModalVisible(true);
    };

    const handleTermsOfServiceClick = (): void => {
        setModalType('termsOfService');
        setModalVisible(true);
    };
    const content = Array.isArray(children) ? children[0] : children;
    if (content && content.includes('{Privacy Policy}')) {
        const newContent = replace(content, /{(.+?)}/g, (match) => {
            if (match === 'Privacy Policy') {
                return (
                    <Button
                        key={match}
                        type="text"
                        className="text-link"
                        onClick={handlePrivacyPolicyClick}
                    >
                        {match}
                    </Button>
                );
            }
            if (match === 'Terms of Service') {
                return (
                    <Button
                        key={match}
                        type="text"
                        className="text-link"
                        onClick={handleTermsOfServiceClick}
                    >
                        {match}
                    </Button>
                );
            }
            return (
                <Button
                    key={match}
                    type="text"
                    href="https://www.truepill.com/notice-of-privacy-practices"
                    className="text-link"
                    target="_blank"
                >
                    {match}
                </Button>
            );
        });

        return (
            <>
                {newContent}
                <PolicyModal
                    visible={modalVisible}
                    setVisible={setModalVisible}
                    policyType={modalType}
                />
            </>
        );
    }
    return <>{content}</>;
}

export const PhoneNumber = ({
    children,
}: {
    children: string | string[];
}): ReactElement => {
    const regex = /(\+?1?\s?\(?[0-9]{3}\)?[-. ]?[0-9]{3}[-. ]?[0-9]{4})/;
    const content = Array.isArray(children) ? children[0] : children;

    const newContent = replace(content, regex, (match) => {
        return (
            <a href={`tel:${match}`}>
                <span className="text-link">{match}</span>
            </a>
        );
    });

    return <>{newContent}</>;
};
