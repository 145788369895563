/* eslint-disable no-nested-ternary */
import { Button, Row, Col, Typography } from 'antd';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import { ReactElement, useState } from 'react';
import './pharmacyCard.less';
import { findNextOpen, TimePeriods } from '../../utils/pharmacyUtils';

const { Paragraph } = Typography;

interface CardProps {
    name: string;
    milesAway: number;
    address: string;
    zipCode: string;
    phone: string;
    isOpen: boolean | string;
    openingHoursArray: TimePeriods[] | string;
    openingHoursText: string[];
    radio: ReactElement;
    isActive: boolean;
    handlePharmacySelect: () => void;
}

interface HourProps {
    day: string;
    hours: string;
}

function HoursEntry({ day, hours }: HourProps): ReactElement {
    const splitHours = hours.split(': ');
    return (
        <Row className="pharmacy-hours-row">
            <Col span={10}>{day}</Col>
            <Col span={14}>{splitHours[1]}</Col>
        </Row>
    );
}
function openingHoursButtonText(
    isOpen: boolean | string,
    hoursArray: TimePeriods[] | string,
    hoursText: string[]
): ReactElement {
    const removeDay = hoursText[0].split(': ')[1];
    const splitTimes = removeDay?.split(', ');
    const firstText = isOpen
        ? splitTimes[0]
        : Array.isArray(hoursArray)
        ? findNextOpen(hoursArray)
        : hoursArray;
    const secondText = splitTimes[1];
    return (
        <>
            <Row>
                <Col
                    span={isOpen ? 8 : 6}
                    className={isOpen ? 'text-blue' : 'text-red'}
                >
                    {isOpen ? 'Open:' : 'Closed:'}
                </Col>
                <Col span={isOpen ? 16 : 18}>{firstText}</Col>
            </Row>
            {splitTimes[1] && isOpen && (
                <Row>
                    <Col span={8} />
                    <Col span={16}>{secondText}</Col>
                </Row>
            )}
        </>
    );
}
export default function PharmacyCard({
    name,
    milesAway,
    address,
    zipCode,
    phone,
    isOpen,
    openingHoursArray,
    openingHoursText,
    radio,
    isActive,
    handlePharmacySelect,
}: CardProps): ReactElement {
    const [hoursShown, setHoursShown] = useState(false);
    return (
        <div className="pharmacy-card">
            <Button
                className={`pharmacy-card-button ${isActive ? 'active' : ''}`}
                onClick={() => handlePharmacySelect()}
            >
                {radio}
                <Paragraph className="pharmacy-card-button--label">
                    {name}
                </Paragraph>
                <div
                    className={`pharmacy-card-button--distance ${
                        isActive ? 'active' : ''
                    }`}
                >
                    <Paragraph className="text-white">
                        {milesAway} MILES
                    </Paragraph>
                </div>
            </Button>
            <div className="pharmacy-card-content">
                <div className="pharmacy-card-content--text">
                    <Paragraph>{address}</Paragraph>
                    <Paragraph>{zipCode}</Paragraph>
                    <Paragraph>
                        <span className="text-black">Phone:</span> {phone}
                    </Paragraph>
                    {openingHoursText.length <= 1 && (
                        <Typography className="pharmacy-card-content--hours-label">
                            <span className="text-black">Opening Hours:</span>{' '}
                            {openingHoursText[0]}
                        </Typography>
                    )}
                </div>
                {openingHoursText.length === 7 && (
                    <Button
                        className="pharmacy-card-content--hours-button"
                        onClick={() => setHoursShown(!hoursShown)}
                    >
                        <Paragraph className="pharmacy-card-content--hours-label">
                            {openingHoursButtonText(
                                isOpen,
                                openingHoursArray,
                                openingHoursText
                            )}
                        </Paragraph>
                        {hoursShown ? <UpOutlined /> : <DownOutlined />}
                    </Button>
                )}

                {hoursShown && openingHoursText && (
                    <div className="pharmacy-card-content--hours-list">
                        <HoursEntry day="Monday" hours={openingHoursText[0]} />
                        <HoursEntry day="Tuesday" hours={openingHoursText[1]} />
                        <HoursEntry
                            day="Wednesday"
                            hours={openingHoursText[2]}
                        />
                        <HoursEntry
                            day="Thursday"
                            hours={openingHoursText[3]}
                        />
                        <HoursEntry day="Friday" hours={openingHoursText[4]} />
                        <HoursEntry
                            day="Saturday"
                            hours={openingHoursText[5]}
                        />
                        <HoursEntry day="Sunday" hours={openingHoursText[6]} />
                    </div>
                )}
            </div>
        </div>
    );
}
