import { ReactElement, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { FormContext } from '../../components/Stepper';
import mixpanel, { MixpanelLabels } from '../../utils/mixpanel';

export default function Ineligible(): ReactElement {
    const history = useHistory();

    const getLastItem = (thePath: string): string =>
        thePath.substring(thePath.lastIndexOf('/') + 1);
    const currentStep = getLastItem(history.location.pathname);

    useEffect(() => {
        if (currentStep === 'ineligible') {
            mixpanel.track(MixpanelLabels.INELIGIBLE_PAGE);
        }
    }, [currentStep]);

    const form = useContext(FormContext);
    form?.resetFields();
    localStorage.removeItem('formData');
    localStorage.removeItem('stripeClientSecret');

    return <></>;
}
