import { CheckCircleFilled } from '@ant-design/icons';
import { Typography } from 'antd/lib';
import { ReactElement, useEffect } from 'react';
import { checkout as checkoutContent } from '../../staticContent.json';
import './checkoutComplete.less';
import mixpanel, { MixpanelLabels } from '../../utils/mixpanel';

const { Title, Paragraph } = Typography;
const content = checkoutContent.completed;

export default function Checkout(): ReactElement {
    useEffect(() => {
        mixpanel.track(MixpanelLabels.CHECKOUT);
    }, []);

    return (
        <div className="checkout-complete">
            <div className="checkout-complete--container">
                <CheckCircleFilled style={{ color: 'green' }} />
                <Title className="checkout-complete--title">
                    {content.title}
                </Title>
                <Paragraph className="text-black checkout-complete--bold-desc">
                    {content.desc_1}
                </Paragraph>
                <Paragraph>{content.desc_2}</Paragraph>
            </div>
        </div>
    );
}
