import { RuleRender } from 'antd/lib/form';

export default function zipValidator(fieldName: string): RuleRender {
    return ({ getFieldValue }) => ({
        validator() {
            const zip: string = getFieldValue(fieldName);
            if (!zip) {
                return Promise.resolve();
            }
            if (zip.length === 0) {
                return Promise.resolve();
            }
            if (!/^\d{5}$/.test(zip)) {
                return Promise.reject(new Error('Invalid zip code'));
            }
            return Promise.resolve();
        },
    });
}
