import {
    Dispatch,
    ReactElement,
    SetStateAction,
    useEffect,
    useState,
} from 'react';
import { Button, Modal } from 'antd';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { HeadingProps } from 'react-markdown/lib/ast-to-react';
import termsOfServiceMarkdown from './TermsOfService.md';
import privacyPolicyMarkdown from './PrivacyPolicy.md';
import toLowerKebabCase from '../../helpers/toLowerKebabCase';
import { uriTransformer } from './TransformLinkUri';
import './PolicyModal.less';

const camelToSentence = (str: string): string => {
    return str.replace(/([A-Z])/g, ' $1').replace(/^./, (first) => {
        return first.toUpperCase();
    });
};

export type PolicyType = 'termsOfService' | 'privacyPolicy';

export default ({
    visible,
    setVisible,
    policyType,
}: {
    visible: boolean;
    setVisible: Dispatch<SetStateAction<boolean>>;
    policyType: PolicyType;
}): ReactElement => {
    const [termsOfService, setTermsOfService] = useState('');
    const [privacyPolicy, setPrivacyPolicy] = useState('');
    const [currentPolicy, setCurrentPolicy] = useState(policyType);

    useEffect(() => {
        setCurrentPolicy(policyType);
    }, [policyType]);

    useEffect(() => {
        const isTest = process.env.NODE_ENV === 'test';
        if (!isTest) {
            fetch(termsOfServiceMarkdown)
                .then((response) => response.text())
                .then((text) => {
                    setTermsOfService(text);
                });

            fetch(privacyPolicyMarkdown)
                .then((response) => response.text())
                .then((text) => {
                    setPrivacyPolicy(text);
                });
        }
    }, []);

    const handleOk = (): void => {
        setVisible(false);
        setCurrentPolicy(policyType);
    };

    const handleCancel = (): void => {
        setVisible(false);
        setCurrentPolicy(policyType);
    };
    return (
        <Modal
            title={camelToSentence(currentPolicy)}
            visible={visible}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={null}
            className="privacy-policy-modal"
        >
            <ReactMarkdown
                remarkPlugins={[remarkGfm]}
                linkTarget={(href): string => {
                    if (href.startsWith('#')) {
                        return '_self';
                    }
                    return '_blank';
                }}
                transformLinkUri={uriTransformer}
                components={{
                    h3: (props: HeadingProps): ReactElement => (
                        <h3
                            id={toLowerKebabCase(
                                typeof props?.children?.[0] === 'string'
                                    ? props?.children?.[0]
                                    : ''
                            )}
                        >
                            {props.children}
                        </h3>
                    ),
                    code: (): ReactElement => (
                        <Button
                            type="text"
                            onClick={() => setCurrentPolicy('privacyPolicy')}
                            className="text-link"
                        >
                            Privacy Policy
                        </Button>
                    ),
                }}
            >
                {currentPolicy === 'termsOfService'
                    ? termsOfService
                    : privacyPolicy}
            </ReactMarkdown>
        </Modal>
    );
};
