const centsToDollars = (cents: number | undefined): string => {
    if (cents) {
        const dollars = cents / 100;
        return dollars.toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
        });
    }
    return '';
};
export default centsToDollars;
