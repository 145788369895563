/* eslint-disable react/jsx-props-no-spreading */
import { Group, RadioGroupProps } from 'antd/lib/radio';
import { ReactElement, RefAttributes } from 'react';
import './HorizontalRadio.less';

export default function RadioGroup(
    props: JSX.IntrinsicAttributes &
        RadioGroupProps &
        RefAttributes<HTMLDivElement>
): ReactElement {
    return <Group {...props} className="vec-radio-group-horizontal" />;
}
