import { LoadingOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { ReactElement, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './userSignUp.less';

export default (): ReactElement => {
    const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();
    const history = useHistory();

    useEffect(() => {
        if (!isLoading && !isAuthenticated) {
            loginWithRedirect({ mode: 'signUp' });
        }
        if (!isLoading && isAuthenticated) {
            history.replace('/form/sign-up-complete');
        }
    }, [isLoading, isAuthenticated, loginWithRedirect, history]);

    return <LoadingOutlined />;
};
