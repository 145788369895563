/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import { Button } from 'antd/lib/radio';
import { RadioButtonProps } from 'antd/lib/radio/radioButton';
import { ReactElement, RefAttributes } from 'react';
import './HorizontalRadio.less';

export default function RadioButton(
    props: JSX.IntrinsicAttributes & RadioButtonProps & RefAttributes<any>
): ReactElement {
    return <Button {...props} className="vec-radio-button-horizontal" />;
}
