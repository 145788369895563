import { ReactElement, useContext, useEffect } from 'react';
import { utcToZonedTime } from 'date-fns-tz';
import { FormContext } from '../Stepper';
import { confirmation as content } from '../../staticContent.json';

import './ConsultationSummary.less';

interface Props {
    setIsBusinessHours: React.Dispatch<React.SetStateAction<boolean | null>>;
}

export default function ConsultationSummary({
    setIsBusinessHours,
}: Props): ReactElement {
    const form = useContext(FormContext);
    const formData = form?.getFieldsValue(true) || {};
    const pharmacy = formData?.pharmacy || {};
    const { address, phone, zipCode } = pharmacy;

    const date = new Date();
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    const zonedDate = utcToZonedTime(date, timeZone);
    const dateString = zonedDate.toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
    });
    const timeString = zonedDate.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    });

    const hours = zonedDate.getHours();
    const isBusinessHours = hours >= 9 && hours <= 18;

    useEffect(() => {
        setIsBusinessHours(isBusinessHours);
    }, [isBusinessHours, setIsBusinessHours]);

    return (
        <div className="consult-card">
            <h1>{content.card_title}</h1>
            <div>
                <div className="booking-info">
                    <p>
                        <strong>Date: </strong> {dateString}
                    </p>
                    <p>
                        <strong>Booking time: </strong> {timeString}
                    </p>
                    <p>
                        <strong>Consult type: </strong>
                        {isBusinessHours ? 'Business hours' : 'After hours'}
                    </p>
                    <p>
                        <strong>Fee:</strong> {isBusinessHours ? '$50' : '$60'}
                    </p>
                </div>

                <div className="pharmacy-info">
                    <strong>Nominated pharmacy</strong>
                    <p>
                        {address}
                        <br />
                        {zipCode} <br />
                        {phone}
                    </p>
                </div>
            </div>
        </div>
    );
}
