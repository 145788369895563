import React, {
    ReactElement,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react';
import { Button, Col, Row, Typography } from 'antd';
import ReactMarkdown from 'react-markdown';
import { ArrowDownOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { FormContext } from '../../components/Stepper';

import markdown from './InformedConsent.md';
import './InformedConsent.less';

import { informed_consent as content } from '../../staticContent.json';

const { Title } = Typography;

export default (): ReactElement => {
    const [markdownContent, setMarkdownContent] = useState('');
    const [isBottom, setIsBottom] = useState(false);

    const contentRef = useRef<null | HTMLDivElement>(null);
    const bottom = useRef<null | HTMLDivElement>(null);

    const history = useHistory();

    const form = useContext(FormContext);

    const onScroll = (): void => {
        if (contentRef.current) {
            const { scrollTop, scrollHeight, clientHeight } =
                contentRef.current;
            if (scrollTop + clientHeight >= scrollHeight - 100) {
                setIsBottom(true);
            } else {
                setIsBottom(false);
            }
        }
    };

    useEffect(() => {
        fetch(markdown)
            .then((response) => response.text())
            .then((text) => setMarkdownContent(text));
    }, []);

    return (
        <div className="informed-consent-container">
            <Row
                className="informed-consent-content"
                onScroll={() => onScroll()}
                ref={contentRef}
            >
                <Col xs={0} md={4} lg={8} />
                <Col xs={24} md={16} lg={8} className="button-padding">
                    <Title>{content.title}</Title>
                    <ReactMarkdown>{markdownContent}</ReactMarkdown>
                    <div ref={bottom} />
                </Col>
                <Col xs={0} md={4} lg={8} />
            </Row>

            <Row className="informed-consent-button-row">
                <Col xs={0} md={4} lg={8} />
                <Col
                    xs={24}
                    md={16}
                    lg={8}
                    className="informed-consent-button-container"
                >
                    {!isBottom ? (
                        <Button
                            type="primary"
                            shape="circle"
                            icon={<ArrowDownOutlined />}
                            size="large"
                            className="informed-consent-button"
                            onClick={() => {
                                bottom?.current?.scrollIntoView({
                                    behavior: 'smooth',
                                });
                            }}
                        />
                    ) : (
                        <Button
                            type="primary"
                            block
                            className="primary-form-cta"
                            onClick={() => {
                                form?.setFieldsValue({
                                    informedConsent: true,
                                });
                                history.push('weight');
                            }}
                        >
                            {content.cta}
                        </Button>
                    )}
                </Col>
                <Col xs={0} md={4} lg={8} />
            </Row>
        </div>
    );
};
