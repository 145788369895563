import { ReactElement } from 'react';
import { Route } from 'react-router-dom';
import UserLogin from '../../pages/UserLogin';
import UserOrders from '../../pages/UserOrders';
import Auth0Wrapper from '../Auth0Wrapper';
import Footer from '../Footer';
import HeaderWithLogo from '../HeaderWithLogo';
import ProtectedRoute from '../ProtectedRoute/ProtectedRoute';
import ResponsiveGrid from '../ResponsiveGrid';

function Router(): ReactElement {
    return (
        <>
            <HeaderWithLogo />
            <div className="content">
                <ResponsiveGrid>
                    <Route exact path="/user" component={UserLogin} />
                    <ProtectedRoute
                        path="/user/orders"
                        component={UserOrders}
                    />
                </ResponsiveGrid>
                <div className="grow" />
                <Footer />
            </div>
        </>
    );
}

export default Auth0Wrapper(Router, `${window.location.origin}/user`);
