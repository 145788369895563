import { ReactElement } from 'react';
import { Layout } from 'antd';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import './styles/app.less';
import Routes from './Router';
import ScrollToTop from './components/ScrollToTop';
import InactiveModal from './components/InactiveModal';

const { Content } = Layout;

function App(): ReactElement {
    const history = createBrowserHistory();

    return (
        <Router history={history}>
            <ScrollToTop />
            <Layout>
                <Content>
                    <InactiveModal />
                    <Routes />
                </Content>
            </Layout>
        </Router>
    );
}

export default App;
