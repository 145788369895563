import { ReactElement, useState } from 'react';
import { Button, Typography, Form, Input } from 'antd';
import './pharmacySelect.less';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import zipValidator from '../../helpers/zipValidator';
import { pharmacy as content } from '../../staticContent.json';
import InputNumber from '../../components/InputNumber';
import { Pharmacies } from './PharmacySelect';

const { Paragraph } = Typography;
const { Item } = Form;

interface SearchCriteriaProps {
    setPharmacies: (pharmacies: Pharmacies[] | null) => void;
    setValue: (index: number | null) => void;
    setLoadingError: (value: boolean) => void;
    zipCode: string;
    setZipCode: (code: string) => void;
    setPharmacyName: (name: string) => void;
}

export default function SearchCriteria({
    setPharmacies,
    setValue,
    setLoadingError,
    zipCode,
    setZipCode,
    setPharmacyName,
}: SearchCriteriaProps): ReactElement {
    const [searchCriteriaShown, setSearchCriteriaShown] = useState(false);
    const handleSearchCriteriaChange = async (): Promise<void> => {
        if (/^\d{5}$/.test(zipCode)) {
            setPharmacies(null);
            setValue(null);
            setLoadingError(false);
        }
    };

    return (
        <div className="search-criteria">
            <Paragraph className="search-criteria--text text-black">
                {content.search_criteria.label}
            </Paragraph>
            <Button
                className="search-criteria--button"
                onClick={() => setSearchCriteriaShown(!searchCriteriaShown)}
            >
                <Paragraph>{content.search_criteria.cta}</Paragraph>
                {searchCriteriaShown ? <UpOutlined /> : <DownOutlined />}
            </Button>
            {searchCriteriaShown && (
                <>
                    <Item
                        className="search-criteria--zip"
                        name="pharmacyZipCode"
                        label="Zip code"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter a valid number',
                            },
                            zipValidator('pharmacyZipCode'),
                        ]}
                        hasFeedback
                    >
                        <InputNumber
                            data-testid="pharmacy-select-test-id"
                            onChange={(e) => setZipCode(e.target.value)}
                        />
                    </Item>
                    <Item
                        className="search-criteria--zip"
                        name="pharmacyNameFilter"
                        label="Search by name (optional)"
                    >
                        <Input
                            data-testid="pharmacy-name-select-test-id"
                            onChange={(e) => setPharmacyName(e.target.value)}
                        />
                    </Item>
                    <Button
                        className="submit-pharmacy-search"
                        type="primary"
                        onClick={() => handleSearchCriteriaChange()}
                    >
                        Search
                    </Button>
                </>
            )}
        </div>
    );
}
