import { ReactElement, useContext, useEffect, useState } from 'react';

import { Space, Checkbox, Form, Input } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { FormContext } from '../Stepper';

const { Group } = Checkbox;
const { Item } = Form;

interface Props {
    question: string;
    options: {
        label: string;
        value: string;
        none?: boolean;
    }[];
}

export default function CheckboxInput({
    question,
    options,
}: Props): ReactElement {
    const form = useContext(FormContext);
    const formData = form?.getFieldsValue(true);

    const noneOption = options.find((curr) => curr.none);

    const initialValue = formData?.[question] || [];

    const [checkedList, setCheckedList] =
        useState<CheckboxValueType[]>(initialValue);

    const [noneValue, setNoneValue] = useState<boolean>(
        initialValue.includes(noneOption?.value)
    );

    const optionsWithoutNone = options.filter((option) => option.none !== true);

    const onNone = (e: CheckboxChangeEvent): void => {
        if (noneOption) {
            setNoneValue(e.target.checked);
            setCheckedList([noneOption?.value]);
        }
    };

    const onChange = (list: CheckboxValueType[]): void => {
        setCheckedList(list);
        setNoneValue(false);
    };

    useEffect(() => {
        form?.setFieldsValue({
            [question]: checkedList,
        });
    }, [checkedList, form, noneOption?.value, noneValue, question]);

    return (
        <Item>
            <Group
                data-testid={question}
                className="clinical-protocol-checkbox-group full-width"
                value={checkedList}
                onChange={onChange}
            >
                <Space direction="vertical" className="full-width">
                    {optionsWithoutNone?.map(({ label, value }) => (
                        <Checkbox
                            key={value}
                            value={value}
                            className="clinical-protocol-checkbox-item full-width"
                        >
                            {label}
                        </Checkbox>
                    ))}
                </Space>
            </Group>
            {noneOption && (
                <Checkbox
                    value={noneOption.value}
                    checked={noneValue}
                    className="clinical-protocol-checkbox-item full-width none-option"
                    onChange={onNone}
                >
                    {noneOption.label}
                </Checkbox>
            )}
            {!checkedList.length && (
                <Item
                    name={`${question}-hidden-input`}
                    rules={[
                        {
                            required: true,
                            message: 'Please answer this question',
                        },
                    ]}
                >
                    <Input type="hidden" />
                </Item>
            )}
        </Item>
    );
}
