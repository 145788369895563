/* eslint-disable no-console */
import { Button, Form, FormInstance, PageHeader, Row, Typography } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import React, {
    PropsWithChildren,
    ReactElement,
    useEffect,
    useState,
    createContext,
    isValidElement,
    Children,
} from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import ProtectedRoute from './ProtectedRoute';
import ResponsiveGrid from '../ResponsiveGrid/ResponsiveGrid';
import './stepper.less';
import { createOrder } from '../../pages/Form/orderAdapter';
import orderHook from '../../pages/Form/orderApiInterface';

import InterstitialContent from '../InterstitialContent';
import Ineligible from '../../pages/Ineligible';
import {
    ineligible as ineligibleContent,
    confirmation as confirmationContent,
    informed_consent as informedConsentContent,
    error as errorContent,
    clinical_protocol as content,
} from '../../staticContent.json';
import ProgressBar from '../ProgressBar/ProgressBar';
import HeaderWithLogo from '../HeaderWithLogo';
import {
    getWithExpiry,
    setWithExpiry,
} from '../../helpers/localStorageWithExpiry';
import mixpanel, { MixpanelLabels } from '../../utils/mixpanel';

const { Text } = Typography;

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface PropsInterface {
    stepForm: FormInstance;
}

interface FormData {
    symptoms: string[];
}

interface SelectedSymptoms {
    SymptomA?: string;
    SymptomB?: string;
    SymptomC?: string;
    SymptomD?: string;
    SymptomE?: string;
    SymptomF?: string;
}

interface Urac {
    patientToken: string;
    doYouHaveAnyAllergies: 'yes' | 'no';
    listAllergies?: string;
    healthConditions: 'yes' | 'no';
    listHealthConditions?: string;
    medicationHistory: 'yes' | 'no';
    listMedications?: string;
    updatedAt?: string;
}

export interface FormValues {
    order?: {
        link: string;
        id: string;
        payment: {
            amount: number;
            cardBrand: string;
            cardLast4: string;
        };
    };
    firstName: string;
    lastName: string;
    gender: string;
    day: string;
    month: string;
    year: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    state: string;
    homeZip: string;
    termsConsent: boolean;
    informedConsent: boolean;
    userId: string;
    antibiotic: string;
    weight: string;
    whichEar: string;
    pulling: string;
    infectionLastDays: string;
    symptoms: string[];
    leftVideo: string;
    rightVideo: string;
    pharmacy: { name: string; address: string; zipCode: string; phone: string };
    patientPhone: string;
    paymentId: string;
    urac: Urac;
}

export const FormContext = createContext<FormInstance | null>(null);

export default function Stepper({
    children,
    stepForm,
}: PropsWithChildren<PropsInterface>): ReactElement {
    const existingForm = JSON.parse(getWithExpiry('formData') || '{}');
    const history = useHistory();
    const { path } = useRouteMatch();
    const { user, isAuthenticated, getAccessTokenSilently, isLoading } =
        useAuth0();
    const [
        authenticatedPriorToReachingSignup,
        setAuthenticatedPriorToReachingSignup,
    ] = useState(false);

    const steps = Children.toArray(children);
    const initialPathList = steps.reduce(
        (
            accumulator: { path: string; oneTime: boolean; skip: boolean }[],
            currStep
        ) => {
            if (isValidElement(currStep) && !currStep.props.skip)
                accumulator.push(currStep.props);
            return accumulator;
        },
        []
    );

    const [pathList, setPathList] =
        useState<{ path: string; oneTime: boolean; skip: boolean }[]>(
            initialPathList
        );
    useEffect(() => {
        const values = stepForm.getFieldsValue(true);
        if (!values || Object.keys(values).length === 0) {
            stepForm.setFieldsValue(existingForm);
        }
    }, [existingForm, stepForm]);

    useEffect(() => {
        if (isAuthenticated) {
            setPathList((curr) => curr.filter(({ oneTime }) => !oneTime));
        }
    }, [isAuthenticated]);

    const getLastItem = (thePath: string): string =>
        thePath.substring(thePath.lastIndexOf('/') + 1);

    const currentStep = getLastItem(history.location.pathname);

    const currStepIndex = pathList
        .map(({ path: currPath }) => currPath)
        .indexOf(currentStep);

    useEffect(() => {
        if (currentStep === 'health-questions-complete') {
            setAuthenticatedPriorToReachingSignup(isAuthenticated);
        }
    }, [currentStep, isAuthenticated]);

    const getSelectedSymptoms = (formData: FormData): SelectedSymptoms => {
        const { symptoms } = formData;
        const propts = {} as SelectedSymptoms;
        if (symptoms.includes(content.symptoms.symptom_1)) {
            propts.SymptomA = content.symptoms.symptom_1;
        }
        if (symptoms.includes(content.symptoms.symptom_2)) {
            propts.SymptomB = content.symptoms.symptom_2;
        }
        if (symptoms.includes(content.symptoms.symptom_3)) {
            propts.SymptomC = content.symptoms.symptom_3;
        }
        if (symptoms.includes(content.symptoms.symptom_4)) {
            propts.SymptomD = content.symptoms.symptom_4;
        }
        if (symptoms.includes(content.symptoms.symptom_5)) {
            propts.SymptomE = content.symptoms.symptom_5;
        }
        if (symptoms.includes(content.symptoms.no_symptoms)) {
            propts.SymptomF = content.symptoms.no_symptoms;
        }
        return propts;
    };

    const sendTrackingToMixpanel = (formData: FormData): void => {
        const mixpanelMessages: {
            [key: string]: string;
        } = {
            'health-profile': MixpanelLabels.HEALTH_PROFILE_COMPLETION,
            urac: MixpanelLabels.URAC_COMPLETION,
            symptoms: MixpanelLabels.CLINICAL_COMPLETION,
            'sign-up-complete': MixpanelLabels.ACCOUNT_CREATION,
            'left-ear': MixpanelLabels.LEFT_EAR_VIDEO,
            'right-ear': MixpanelLabels.RIGHT_EAR_VIDEO,
            'pharmacy-select': MixpanelLabels.PHARMACY_SELECT,
        };
        const mixpanelEvent = mixpanelMessages?.[currentStep];

        if (mixpanelEvent) {
            if (currentStep === 'symptoms') {
                mixpanel.track(mixpanelEvent, getSelectedSymptoms(formData));
            } else {
                mixpanel.track(mixpanelEvent);
            }
        }
    };

    const onBack = (): void => {
        // if on the illegible page, go back to the home
        if (history.location.pathname.includes('/ineligible')) {
            return history.push('/');
        }
        // skip past auth0 screens in history if relevant
        if (
            currentStep === 'sign-up-complete' &&
            !authenticatedPriorToReachingSignup
        ) {
            history.go(-4);
        }
        if (pathList[currStepIndex - 1]?.oneTime) {
            const previousStep = pathList[currStepIndex - 2].path;
            return history.push(`${path}/${previousStep}`);
        }
        return history.goBack();
    };

    const onNext = async (): Promise<void> => {
        await stepForm.validateFields();
        const hasErrors = stepForm
            .getFieldsError()
            .some(({ errors }) => errors.length);

        if (!hasErrors) {
            const formData = stepForm.getFieldsValue(true);
            setWithExpiry('formData', JSON.stringify(formData), 20 * 60 * 1000);

            sendTrackingToMixpanel(formData);

            if (pathList[currStepIndex]?.oneTime) {
                setPathList(
                    pathList.filter((_, index) => index !== currStepIndex)
                );
            }
            const nextStep = pathList[currStepIndex + 1].path;
            if (`/${currentStep}` === path) {
                history.push(`${path}/${pathList[1].path}`);
            } else if (currentStep.includes('checkout-complete')) {
                // prevents user returning to checkout-complete after fulfilling order
                history.replace(`${path}/${nextStep}`);
            } else if (currentStep.includes('loading')) {
                // prevents user returning to loading after fulfilling order
                history.replace(`${path}/${nextStep}`);
            } else if (
                currentStep === 'health-profile' &&
                formData.patientToken
            ) {
                // Go to urac page if patient already exists
                history.push(`${path}/urac`);
            } else {
                history.push(`${path}/${nextStep}`);
            }
        }
    };

    const onFinish = async (): Promise<void> => {
        const formData = stepForm.getFieldsValue(true);
        const userId = user?.sub;

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { dobDate, ...requestData } = formData;
        const isTest = process.env.NODE_ENV === 'test';

        try {
            const token = !isTest
                ? await getAccessTokenSilently({
                      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                  })
                : '';
            const cb = orderHook(token);

            requestData.userId = userId;
            requestData.urac.doYouHaveAnyAllergies =
                requestData.urac.listAllergies === 'N/A' ? 'no' : 'yes';
            requestData.urac.healthConditions =
                requestData.urac.listHealthConditions === 'N/A' ? 'no' : 'yes';
            requestData.urac.medicationHistory =
                requestData.urac.listMedications === 'N/A' ? 'no' : 'yes';

            const res = await createOrder(cb, requestData);
            const { id, payment, patient, videoLink } = res;

            stepForm.setFieldsValue({
                order: { link: videoLink, id, payment },
                patientPhone: patient.phone,
            });

            localStorage.removeItem('formData');
            const lastStep = pathList[pathList.length - 1].path;
            await history.push(`${path}/${lastStep}`);
        } catch (e) {
            console.log(e);
            history.push(`${path}/error`);
        }
    };

    useEffect(() => {
        if (
            ['loading', 'checkout-complete'].includes(currentStep) &&
            !isLoading
        ) {
            setTimeout(() => {
                onFinish();
            }, 3000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentStep, isLoading]);

    const [showBackIcon, setShowBackIcon] = useState<boolean>(true);
    const [showHeader, setShowHeader] = useState<boolean>(true);
    useEffect(() => {
        setShowBackIcon(
            currentStep !== 'checkout-complete' &&
                currentStep !== 'order-summary'
        );
    }, [currentStep]);

    useEffect(() => {
        setShowHeader(currentStep !== 'loading' && currentStep !== 'error');
    }, [currentStep]);

    const BackIcon = (): ReactElement => (
        <Row>
            <ArrowLeftOutlined className="gutter-right" />
            <Text className="header-button" strong>
                Back
            </Text>
        </Row>
    );

    const validateMessages = {
        // eslint-disable-next-line no-template-curly-in-string
        required: "'${label}' is required",
        // ...
    };

    const Header = (): ReactElement => {
        if (showHeader) {
            return showBackIcon ? (
                <PageHeader
                    backIcon={<BackIcon />}
                    title
                    onBack={onBack}
                    className="no-margin"
                />
            ) : (
                <HeaderWithLogo />
            );
        }
        return <div className="header header-bottom" />;
    };
    return (
        <FormContext.Provider value={stepForm}>
            <Header />
            <ProgressBar currentStep={currentStep} />
            <ResponsiveGrid>
                <Form
                    form={stepForm}
                    name="form"
                    layout="vertical"
                    onFinish={() => {
                        return ['loading', 'checkout-complete'].includes(
                            currentStep
                        )
                            ? onFinish()
                            : onNext();
                    }}
                    autoComplete="off"
                    validateTrigger={['onBlur']}
                    validateMessages={validateMessages}
                >
                    <Switch>
                        {steps.map((currStep, i) => {
                            if (isValidElement(currStep)) {
                                const subPath = currStep.props.path;
                                const { noNext, finalStep } = currStep.props;
                                const getButtonText = (): string => {
                                    if (subPath === 'confirm-consult')
                                        return confirmationContent.cta;
                                    if (subPath === 'informed-consent')
                                        return informedConsentContent.cta;
                                    return 'Continue';
                                };
                                const buttonText = getButtonText();

                                const CurrentStep = (): ReactElement => (
                                    <>
                                        {currStep}
                                        {!noNext && !finalStep && (
                                            <>
                                                <div className="grow-mobile" />
                                                <Button
                                                    type="primary"
                                                    block
                                                    onClick={onNext}
                                                    className="primary-form-cta"
                                                >
                                                    {buttonText}
                                                </Button>
                                            </>
                                        )}
                                        {finalStep && (
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                className="primary-form-cta"
                                            >
                                                Submit
                                            </Button>
                                        )}
                                    </>
                                );

                                return (
                                    <ProtectedRoute
                                        exact={i === 0}
                                        path={`${path}/${subPath}`}
                                        key={subPath}
                                        Component={CurrentStep}
                                    />
                                );
                            }
                            return currStep;
                        })}
                        <Route path={`${path}/ineligible`}>
                            <InterstitialContent
                                Icon={() => (
                                    <div className="stop-circle">
                                        <div />
                                        <div />
                                    </div>
                                )}
                                title={ineligibleContent.title}
                                content={ineligibleContent.desc_1}
                                content2={ineligibleContent.desc_2}
                            />
                            <Ineligible />
                            <div className="grow-mobile" />
                            <Button
                                type="primary"
                                block
                                onClick={() => history.push('/')}
                                className="primary-form-cta"
                            >
                                {ineligibleContent.cta}
                            </Button>
                        </Route>
                        <Route path={`${path}/error`}>
                            <InterstitialContent
                                Icon={() => (
                                    <div className="stop-circle">
                                        <div />
                                        <div />
                                    </div>
                                )}
                                title={errorContent.title}
                                content={errorContent.desc_1}
                                content2={errorContent.desc_2}
                                className="error-interstitial"
                            />
                            <div className="grow-mobile" />
                            <Button
                                type="primary"
                                block
                                onClick={() => {
                                    history.goBack();
                                }}
                                className="primary-form-cta"
                            >
                                {errorContent.cta}
                            </Button>
                        </Route>
                    </Switch>
                </Form>
            </ResponsiveGrid>
        </FormContext.Provider>
    );
}
