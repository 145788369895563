/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/rules-of-hooks */
import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { ComponentType, FC, ReactElement } from 'react';
import { useHistory } from 'react-router';

declare const process: {
    env: {
        REACT_APP_AUTH0_CLIENT_ID: string;
        REACT_APP_AUTH0_DOMAIN: string;
        REACT_APP_AUTH0_AUDIENCE: string;
        NODE_ENV: string;
    };
};

export default function Auth0Wrapper(
    Component: ComponentType,
    redirectUri: string
): FC {
    return (props: any): ReactElement => {
        const history = useHistory();
        const onRedirectCallback = (appState: AppState): void => {
            history.replace(appState?.returnTo || window.location.pathname);
        };

        return (
            <Auth0Provider
                domain={process.env.REACT_APP_AUTH0_DOMAIN}
                clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
                redirectUri={redirectUri}
                onRedirectCallback={onRedirectCallback}
                audience={process.env.REACT_APP_AUTH0_AUDIENCE}
                useRefreshTokens
                cacheLocation="localstorage"
            >
                <Component {...props} />
            </Auth0Provider>
        );
    };
}
