import { Button, Typography } from 'antd';
import { FC, ReactElement, useContext, useEffect } from 'react';
import { utcToZonedTime } from 'date-fns-tz';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import Interweave from 'interweave';
import { summary } from '../../staticContent.json';
import { FormContext } from '../../components/Stepper';
import { FormValues } from '../../components/Stepper/Stepper';
import { amex, discover, mastercard, visa, generic, diners } from './cards';

import './OrderSummary.less';
import { getUsersName } from '../../utils/textUtils';
import mixpanel, { MixpanelLabels } from '../../utils/mixpanel';
import centsToDollar from '../../utils/centsToDollar';

const cardBrandDictionary: Record<string, FC> = {
    amex,
    diners,
    discover,
    mastercard,
    visa,
    default: generic,
};

const { Title } = Typography;
const date = new Date();
const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
const zonedDate = utcToZonedTime(date, timeZone);
const dateString = zonedDate.toLocaleDateString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
});

const timeString = zonedDate.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
    timeZoneName: 'short',
});

export default function OrderSummary(): ReactElement {
    const form = useContext(FormContext);
    const values: FormValues = form?.getFieldsValue(true);
    const context = useAuth0();
    const history = useHistory();
    const { user } = context;

    const amount = centsToDollar(values?.order?.payment?.amount || 0);

    const CardLogo = (): ReactElement => {
        const cardBrand = values?.order?.payment?.cardBrand || 'default';
        const cardBrandLower = cardBrand.toLowerCase();
        const Logo =
            cardBrandDictionary[cardBrandLower] || cardBrandDictionary.default;
        return (
            <div className="card-logo">
                <Logo />
            </div>
        );
    };

    useEffect(() => {
        mixpanel.track(MixpanelLabels.ORDER_CONFIRMATION);
        localStorage.removeItem('stripeClientSecret');
    }, []);

    return (
        <>
            <Title className="order-summary-text">
                {getUsersName(summary.title, user?.given_name || '')}
            </Title>
            <p className="order-summary-text">{summary.desc}</p>
            <p className="order-summary-chip">Order: #{values.order?.id}</p>
            <div className="order-summary-cta-container">
                <Interweave content={summary.cta_info} />
                <Button
                    type="primary"
                    block
                    className="primary-form-cta"
                    onClick={() => {
                        history.push('/user/orders');
                    }}
                >
                    {summary.cta}
                </Button>
            </div>
            <div className="order-summary-cards">
                <div className="summary-card">
                    <h1>{summary.details_title}</h1>
                    <div>
                        <p>
                            <strong>Date: </strong> {dateString}
                        </p>
                        <p>
                            <strong>Booking time: </strong> {timeString}
                        </p>
                        <br />
                        <strong>Patient</strong>
                        <p className="grid">
                            <span className="label">Name: </span>
                            {`${values?.firstName} ${values?.lastName}`}
                        </p>
                        <p className="grid">
                            <span className="label">
                                Residential zip code:{' '}
                            </span>
                            {values.homeZip}
                        </p>
                        <br />
                        <strong>Nominated pharmacy</strong>
                        <p>
                            {values?.pharmacy?.address}
                            <br />
                            {values?.pharmacy?.zipCode} <br />
                            {values?.pharmacy?.phone}
                        </p>
                        <br />
                        <strong>Account holder</strong>
                        <p className="grid">
                            <span className="label">Name: </span>
                            {`${user?.given_name} ${user?.family_name}`}
                        </p>
                        <p className="grid">
                            <span className="label">Email: </span>{' '}
                            {`${user?.email}`}
                        </p>
                        <p className="grid">
                            <span className="label">Phone: </span>
                            {values?.patientPhone}
                        </p>
                    </div>
                </div>
                <div className="summary-card">
                    <h1>{summary.payment_title}</h1>
                    <div>
                        <strong>Items</strong>
                        <div className="payment-grid">
                            <p>Consultation</p>
                            <p>{amount}</p>
                            <strong>Order Total</strong>
                            <strong>{amount}</strong>
                            <hr />
                            <div>
                                <CardLogo />
                                <strong>
                                    Ending in{' '}
                                    {values?.order?.payment?.cardLast4}
                                </strong>
                            </div>
                            <strong>{amount}</strong>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
