import { LoadingOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { ReactElement, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';
import { FormContext } from '../../components/Stepper';
import { account } from '../../staticContent.json';
import { getUserOrders } from './userOrderAdapter';
import userOrderHook from './userOrderApiInterface';

import Card from './Card';
import './UserOrders.less';

export interface Consults {
    id: 'string';
    consultId: 'string';
    patient: {
        firstName: 'string';
        lastName: 'string';
    };
    paymentId: 'string';
    bookingTime: 'string';
    videoLink: 'string';
    transferred: boolean;
}

const ConsultationContent = (): ReactElement => {
    const { user, getAccessTokenSilently } = useAuth0();
    const [isLoading, setIsLoading] = useState(true);
    const [consults, setConsults] = useState<Consults[]>([]);
    useEffect(() => {
        const setUserOrders = async (): Promise<void> => {
            setIsLoading(true);

            const isTest = process.env.NODE_ENV === 'test';

            const token = !isTest
                ? await getAccessTokenSilently({
                      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                  })
                : '';
            const cb = userOrderHook(token);

            const id = !isTest && user?.sub ? user.sub : 'test';
            const userOrders = await getUserOrders(cb, id);

            setConsults(userOrders);
            setIsLoading(false);
        };

        setUserOrders();
    }, [getAccessTokenSilently, user]);

    const history = useHistory();
    useEffect(() => {
        return () => {
            if (history.action === 'POP') {
                history.replace('');
            }
        };
    }, [history]);

    if (isLoading) return <LoadingOutlined />;
    if (consults.length)
        return (
            <>
                {consults.map((consult) => {
                    const patientName = `${consult.patient.firstName} ${consult.patient.lastName}`;
                    return (
                        <Card
                            key={consult.id}
                            orderId={consult.id}
                            patientName={patientName}
                            paymentId={consult.paymentId}
                            bookingTime={consult.bookingTime}
                            link={consult.videoLink}
                            consultId={consult.consultId}
                            transferred={consult.transferred}
                        />
                    );
                })}
            </>
        );
    return <p>No existing consultations, please create a new consult</p>;
};

export default function UserOrders(): ReactElement {
    const history = useHistory();
    const form = useContext(FormContext);
    const startNewConsultation = (): void => {
        form?.resetFields();
        localStorage.removeItem('stripeClientSecret');
        localStorage.removeItem('formData');
        history.push('/form/user-health-profiles');
    };
    return (
        <>
            <h1 className="consultation-title">{account.title}</h1>
            <p>
                {account.desc},{' '}
                <a href="tel:+1 855-618-0190" className="contact-number">
                    contact us.
                </a>
            </p>
            <Button
                type="primary"
                block
                className="primary-cta"
                onClick={() => {
                    startNewConsultation();
                }}
            >
                {account.header_cta}
            </Button>
            <ConsultationContent />
        </>
    );
}
