export interface Consult {
    id: 'string';
    consultId: 'string';
    patient: {
        firstName: 'string';
        lastName: 'string';
    };
    bookingTime: 'string';
    videoLink: 'string';
    paymentId: 'string';
    transferred: boolean;
}

type UserOrdersCallback = (userId: string) => Promise<Consult[]>;

export const getUserOrders = async (
    cb: UserOrdersCallback,
    userId: string
): Promise<Consult[]> => {
    const userOrders = await cb(userId);
    return userOrders;
};
