import { ReactElement, useContext, useEffect, useState } from 'react';
import { Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import { RadioButton, RadioGroup } from '../../components/HorizontalRadio';
import './UserFilter.less';
import { filter as filterContent } from '../../staticContent.json';
import mixpanel, { MixpanelLabels } from '../../utils/mixpanel';
import { FormContext } from '../../components/Stepper';

const { Title } = Typography;

export default function UserFilter(): ReactElement {
    const [value, setValue] = useState<boolean | null>(null);
    const history = useHistory();
    const form = useContext(FormContext);
    useEffect(() => {
        if (value) {
            mixpanel.track(MixpanelLabels.EXISTING_ACCOUNT_YES);
            history.push('/user');
        }
        if (value === false) {
            mixpanel.track(MixpanelLabels.EXISTING_ACCOUNT_NO);
            history.push('/form/new-health-profile');
        }
    }, [history, value]);

    useEffect(() => {
        form?.resetFields();
        localStorage.removeItem('stripeClientSecret');
        localStorage.removeItem('formData');
        mixpanel.track(MixpanelLabels.START_CONSULTATION);
    }, [form]);

    return (
        <>
            <Title className="title top-spacing">{filterContent.title}</Title>
            <RadioGroup
                value={value}
                onChange={(e) => setValue(e.target.value)}
            >
                <RadioButton value>Yes</RadioButton>
                <RadioButton value={false}>No</RadioButton>
            </RadioGroup>
        </>
    );
}
