import axios from 'axios';

interface Config {
    headers?: {
        Authorization: string;
    };
}

interface Urac {
    id: string;
    patientToken: string;
    doYouHaveAnyAllergies: 'yes' | 'no';
    listAllergies?: string;
    healthConditions: 'yes' | 'no';
    listHealthConditions?: string;
    medicationHistory: 'yes' | 'no';
    listMedications?: string;
    updatedAt: string;
}

export default (token?: string) =>
    async (patientToken: string): Promise<Urac> => {
        const { REACT_APP_API_URL, NODE_ENV } = process.env;
        const isTest = NODE_ENV === 'test';

        const config: Config = {};

        if (token) {
            config.headers = {
                Authorization: `Bearer ${token}`,
            };
        }
        const url = `${
            (!isTest && REACT_APP_API_URL) || 'http://localhost:1080'
        }/api/uracs/user/${patientToken}`;
        const { data } = await axios.get(url, config);
        return data;
    };
