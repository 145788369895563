import { format } from 'date-fns';
import { FormValues } from '../../components/Stepper/Stepper';

export interface OrderInterface {
    patient: {
        firstName: string;
        lastName: string;
        gender: string;
        dob: string;
        homeZip: string;
        termsConsent: boolean;
    };
    informedConsent: boolean;
    userToken: string;
    paymentId: string;
    shippingAddress: {
        line1: string;
        line2: string;
        street1: string;
        street2: string;
        city: string;
        state: string;
        zip: string;
    };
    urac: {
        patientToken: string;
        doYouHaveAnyAllergies: 'yes' | 'no';
        listAllergies?: string;
        healthConditions: 'yes' | 'no';
        listHealthConditions?: string;
        medicationHistory: 'yes' | 'no';
        listMedications?: string;
    };
    standardQuestions: {
        allergies?: string;
        medications?: string;
        conditions?: string;
    };
    answers: {
        weight: string;
        whichEar: { answers: string[] };
        pulling: string;
        infectionLastDays: string;
        antibiotic?: string;
        symptoms: {
            answers: string[];
        };
    };
    media: {
        videos: {
            id: string;
        }[];
    };
    preferredTreatment: {
        rx: {
            ndc: string;
            pharmacy: {
                name: string;
                address: string;
                phone: string;
            };
        }[];
    };
}

export interface OrderCallbackInterface {
    id: string;
    videoLink: string;
    patient: {
        phone: string;
    };
    payment: {
        amount: number;
        cardBrand: string;
        cardLast4: string;
    };
}

export type OrderCallback = (
    order: OrderInterface
) => Promise<OrderCallbackInterface>;

export const createOrder = async (
    cb: OrderCallback,
    formFields: FormValues
): Promise<OrderCallbackInterface> => {
    const {
        firstName,
        lastName,
        gender,
        day,
        month,
        year,
        addressLine1,
        addressLine2,
        city,
        state,
        homeZip,
        termsConsent,
        informedConsent,
        userId,
        urac,
        weight,
        whichEar,
        pulling,
        infectionLastDays,
        symptoms,
        leftVideo,
        rightVideo,
        pharmacy,
        paymentId,
        antibiotic,
    } = formFields;

    const dob =
        year &&
        month &&
        day &&
        format(
            new Date(Number(year), Number(month) - 1, Number(day)),
            'yyyy-MM-dd'
        );

    const videoList = [];
    if (leftVideo) videoList.push({ id: leftVideo });
    if (rightVideo) videoList.push({ id: rightVideo });

    const order: OrderInterface = {
        patient: {
            firstName,
            lastName,
            gender,
            dob,
            homeZip,
            termsConsent,
        },
        informedConsent,
        userToken: userId, // Auth0
        paymentId, // Stripe
        // shippingAddress TBD
        shippingAddress: {
            line1: `${firstName} ${lastName}`,
            line2: '',
            street1: addressLine1,
            street2: addressLine2,
            city,
            state,
            zip: homeZip,
        },
        urac,
        standardQuestions: {
            medications: urac.listMedications,
            allergies: urac.listAllergies,
            conditions: urac.listHealthConditions,
        },
        answers: {
            weight: String(weight),
            whichEar: {
                answers: [whichEar],
            },
            pulling,
            infectionLastDays,
            symptoms: {
                answers: symptoms,
            },
        },
        media: {
            videos: videoList,
        },
        preferredTreatment: {
            rx: [
                {
                    ndc: 'ANY',
                    pharmacy: {
                        name: pharmacy.name,
                        phone: pharmacy.phone,
                        address: `${pharmacy.address}, ${pharmacy.zipCode}`,
                    },
                },
            ],
        },
    };

    if (antibiotic) order.answers.antibiotic = antibiotic;

    return cb(order);
};
