import { ReactElement, ReactNode } from 'react';
import { Col, Row } from 'antd';
import './responsiveGrid.less';

interface Props {
    children: ReactNode;
}

export default function ResponsiveGrid({ children }: Props): ReactElement {
    return (
        <Row className="content-wrapper">
            <Col xs={0} md={4} lg={8} />
            <Col xs={24} md={16} lg={8}>
                {children}
            </Col>
            <Col xs={0} md={4} lg={8} />
        </Row>
    );
}
