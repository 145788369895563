/* eslint-disable react/button-has-type */
import { ReactElement, useEffect, useState } from 'react';
import {
    PaymentElement,
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import { Alert, Button, Card, Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import * as stripeJs from '@stripe/stripe-js';
import { checkout as content } from '../../staticContent.json';
import { ReactComponent as ErrorIcon } from '../../media/error.svg';
import './checkout.less';
import { PolicyModals } from '../../utils/textUtils';

const { Title, Paragraph } = Typography;

interface Props {
    stripeClientSecret: string;
}

function CheckoutOverlay(): ReactElement {
    return (
        <div className="checkout-overlay">
            <div className="checkout-overlay--container">
                <LoadingOutlined />
                <Title className="checkout-overlay--title">
                    {content.overlay.title}
                </Title>
                <Paragraph className="text-black checkout-overlay--bold-desc">
                    {content.overlay.desc_1}
                </Paragraph>
                <Paragraph>{content.overlay.desc_2}</Paragraph>
            </div>
        </div>
    );
}

export default function Checkout({ stripeClientSecret }: Props): ReactElement {
    const stripe = useStripe();
    const elements = useElements();

    const [isReady, setIsReady] = useState<boolean>(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [cost, setCost] = useState<string | null>(null);
    const [error, setError] = useState<string>('');
    const [showError, setShowError] = useState<boolean>(false);

    useEffect(() => {
        let isMounted = true;
        stripe
            ?.retrievePaymentIntent(stripeClientSecret)
            .then(({ paymentIntent }) => {
                if (isMounted) setCost(String(paymentIntent?.amount));
            });
        return () => {
            isMounted = false;
        };
    }, [stripe, stripeClientSecret]);

    const handlePayment = async (): Promise<void> => {
        const url = `${window.origin}/form/checkout-complete`;

        if (!stripe || !elements) {
            return;
        }

        setShowError(false);
        setIsProcessing(true);

        const { error: stripeError } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: url,
                payment_method_data: {
                    billing_details: {
                        address: {
                            country: 'US',
                        },
                    },
                },
            },
        });

        if (
            stripeError.type === 'card_error' ||
            stripeError.type === 'validation_error'
        ) {
            setError(String(stripeError.message));
            setShowError(true);
        } else {
            setError('An unexpected error occured.');
            setShowError(true);
        }

        setIsProcessing(false);
    };

    const options: stripeJs.StripePaymentElementOptions = {
        fields: {
            billingDetails: {
                address: {
                    country: 'never',
                },
            },
        },
    };

    return (
        <div id="checkout">
            <Title id="checkout-title">{content.title}</Title>
            <div id="checkout-cost">
                <Paragraph className="text-black">{content.total}</Paragraph>
                <Paragraph className="text-black">
                    {cost
                        ? `$${cost.substring(
                              0,
                              cost.length - 2
                          )}.${cost.substring(cost.length - 2, cost.length)}`
                        : 'Loading...'}
                </Paragraph>
            </div>
            <Paragraph>{content.desc}</Paragraph>
            <Card title={content.card_title}>
                {!isReady && <LoadingOutlined />}
                <PaymentElement
                    id="payment-element"
                    onReady={() => setIsReady(true)}
                    options={options}
                />
            </Card>
            <div className="checkout-infobox">
                <Paragraph className="checkout-infobox--title text-black">
                    {content.confirm.title}
                </Paragraph>
                <Paragraph className="checkout-infobox--desc">
                    <PolicyModals>{content.confirm.desc} </PolicyModals>
                </Paragraph>
            </div>
            <div className="grow-mobile" />
            <Button
                id="checkout-pay-cta"
                type="primary"
                block
                className="primary-form-cta"
                disabled={isProcessing || !stripe || !elements || !isReady}
                onClick={handlePayment}
            >
                {content.cta}
            </Button>
            {isProcessing && <CheckoutOverlay />}
            {showError && (
                <Alert
                    type="error"
                    message={error}
                    onClose={() => setShowError(false)}
                    icon={<ErrorIcon />}
                    showIcon
                    closable
                />
            )}
        </div>
    );
}
