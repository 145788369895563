import axios from 'axios';
import { Consult } from './userOrderAdapter';
import { Consults } from './UserOrders';

interface Config {
    headers?: {
        Authorization: string;
    };
}

interface ApiResponse {
    id: 'string';
    createdAt: 'string';
    consultId: 'string';
    patient: {
        firstName: 'string';
        lastName: 'string';
        patientToken: 'string';
    };
    paymentId: 'string';
    videoLink: 'string';
    transferred: boolean;
}

export default (token?: string) =>
    async (userId: string): Promise<Consult[]> => {
        const { REACT_APP_API_URL, NODE_ENV } = process.env;
        const isTest = NODE_ENV === 'test';

        const config: Config = {};

        if (token) {
            config.headers = {
                Authorization: `Bearer ${token}`,
            };
        }
        const url = `${
            (!isTest && REACT_APP_API_URL) || 'http://localhost:1080'
        }/api/orders/user/${userId}`;
        const { data } = await axios.get(url, config);
        return data
            .map((consult: ApiResponse): Consults => {
                return {
                    id: consult.id,
                    consultId: consult.consultId,
                    patient: {
                        firstName: consult.patient.firstName,
                        lastName: consult.patient.lastName,
                    },
                    paymentId: consult.paymentId,
                    bookingTime: consult.createdAt,
                    videoLink: consult.videoLink,
                    transferred: consult.transferred,
                };
            })
            .sort((a: Consults, b: Consults) => {
                return (
                    new Date(b.bookingTime).getTime() -
                    new Date(a.bookingTime).getTime()
                );
            });
    };
