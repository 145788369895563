import { ReactElement, useContext, useEffect, useState } from 'react';
import { Form, Input, Typography, Radio, Space } from 'antd';
import './ClinicalProtocol.less';
import MultiInput from '../../components/MultiInput';
import CheckboxInput from '../../components/CheckboxInput';
import { clinical_protocol as content } from '../../staticContent.json';

import { FormContext } from '../../components/Stepper';
import { getPatientName } from '../../utils/textUtils';
import InputNumber from '../../components/InputNumber';

const { Group: RadioGroup } = Radio;

const { Title } = Typography;

const { Item } = Form;

export type ClinicalQuestionType =
    | 'weight'
    | 'whichEar'
    | 'pulling'
    | 'infectionLastDays'
    | 'symptoms';

interface Props {
    question: ClinicalQuestionType;
}

const questionsConfig: {
    [key in ClinicalQuestionType]: {
        label: string;
        type?: string;
        options?: {
            label: string;
            value: string;
            none?: boolean;
        }[];
        extra?: {
            label: string;
            prompt: string;
            type: string;
            options?: {
                label: string;
                value: string;
            }[];
            if: string;
            name: string;
            errorMessage?: string;
        };
    };
} = {
    weight: {
        label: content.weight,
        type: 'number',
    },
    whichEar: {
        label: content.which_ear,
        type: 'radio',
        options: [
            { label: 'Left', value: 'Left' },
            { label: 'Right', value: 'Right' },
            { label: 'Both', value: 'Both' },
        ],
    },
    pulling: {
        label: content.pulling,
        type: 'radio',
        options: [
            { label: 'Yes', value: 'yes' },
            { label: 'No', value: 'no' },
        ],
    },
    infectionLastDays: {
        label: content.infection_last_days,
        type: 'radio',
        options: [
            { label: 'Yes', value: 'yes' },
            { label: 'No', value: 'no' },
        ],
        extra: {
            if: 'yes',
            label: content.antibiotic_prompt_1,
            prompt: content.antibiotic_prompt_2,
            name: 'antibiotic',
            type: 'multi',
            errorMessage: content.antibiotic_error,
        },
    },
    symptoms: {
        label: content.symptoms.title,
        type: 'checkbox',
        options: [
            {
                label: content.symptoms.symptom_1,
                value: content.symptoms.symptom_1,
            },
            {
                label: content.symptoms.symptom_2,
                value: content.symptoms.symptom_2,
            },
            {
                label: content.symptoms.symptom_3,
                value: content.symptoms.symptom_3,
            },
            {
                label: content.symptoms.symptom_4,
                value: content.symptoms.symptom_4,
            },
            {
                label: content.symptoms.symptom_5,
                value: content.symptoms.symptom_5,
            },
            {
                label: content.symptoms.no_symptoms,
                value: content.symptoms.no_symptoms,
                none: true,
            },
        ],
    },
};

const CurrentInput = (props: Props): ReactElement => {
    const { question } = props;
    const form = useContext(FormContext);

    const formData = form?.getFieldsValue(true);

    const initialValue = formData?.[question];
    const { type, options = [], extra } = questionsConfig?.[question] || {};
    const [currValue, setCurrValue] = useState<string>(initialValue || '');

    useEffect(() => {
        if (extra && extra.if !== currValue) {
            form?.setFieldsValue({
                [extra.name]: undefined,
            });
        }
    }, [currValue, extra, form]);

    if (type === 'number')
        return (
            <Item
                name={question}
                rules={[
                    {
                        required: true,
                        pattern: /^[0-9]+$/,
                        message: 'Please enter a valid number',
                    },
                ]}
                required
            >
                <InputNumber data-testid={question} />
            </Item>
        );
    if (type === 'radio')
        return (
            <>
                <Item
                    name={question}
                    rules={[
                        {
                            required: true,
                            message: 'Please answer this question',
                        },
                    ]}
                >
                    <RadioGroup
                        data-testid={question}
                        optionType="default"
                        className="clinical-protocol-radio-group full-width"
                        value={currValue}
                        onChange={(e) => setCurrValue(e.target.value)}
                    >
                        <Space direction="vertical" className="full-width">
                            {options?.map(({ label, value }) => (
                                <Radio
                                    key={value}
                                    value={value}
                                    className="clinical-protocol-radio-item full-width"
                                >
                                    {label}
                                </Radio>
                            ))}
                        </Space>
                    </RadioGroup>
                </Item>
                {extra && extra.if === currValue ? (
                    <MultiInput
                        label={getPatientName(
                            extra.label,
                            form?.getFieldValue('firstName')
                        )}
                        name={extra.name}
                        prompt={extra.prompt}
                        errorMessage={extra.errorMessage}
                    />
                ) : null}
            </>
        );
    if (type === 'checkbox')
        return <CheckboxInput question={question} options={options} />;
    return (
        <Item
            name={question}
            rules={[
                {
                    required: true,
                    message: 'Please answer this question',
                },
            ]}
        >
            <Input data-testid={question} />
        </Item>
    );
};

export default ({ question }: Props): ReactElement => {
    const form = useContext(FormContext);
    const { label } = questionsConfig?.[question] || {};
    const patientName = form?.getFieldValue('firstName');
    return (
        <div className="clinical-protocol">
            <Title className="clinical-protocol-title">
                {getPatientName(label, patientName)}
            </Title>

            <CurrentInput question={question} />
        </div>
    );
};
