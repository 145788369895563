import { PlusOutlined } from '@ant-design/icons';
import { Typography, Input, Form, Button, Tag } from 'antd';
import { ReactElement, useContext, useState } from 'react';

import { FormContext } from '../Stepper';

const { Item } = Form;

interface Props {
    label: string;
    name: string;
    errorMessage?: string;
    prompt: string;
}

export default function MultiInput({
    label,
    name,
    errorMessage,
    prompt,
}: Props): ReactElement {
    const form = useContext(FormContext);
    const formData = form?.getFieldsValue(true);

    const [values, setValues] = useState<string[]>(
        formData[name]?.split(',') || []
    );
    const [value, setValue] = useState('');

    const addValue = (e: { preventDefault: () => void }): void => {
        e.preventDefault();
        if (value) {
            const newValues = [...values, value];
            setValues(newValues);
            setValue('');
            form?.setFieldsValue({ [name]: newValues.join(',') });
        }
    };

    return (
        <Item
            label={label}
            data-testid={`${name}-test-id`}
            className="urac-multi-input"
        >
            <Typography className="urac-sub-header">{prompt}</Typography>
            <Input
                id={`form_${name}`}
                value={value}
                onChange={(e) => setValue(e.target.value)}
                suffix={
                    <Button
                        className="input-button"
                        ghost
                        icon={<PlusOutlined />}
                        onClick={addValue}
                    />
                }
                onPressEnter={addValue}
            />
            <div className="urac-tag-container">
                {values.map((currValue, index) => (
                    <Tag
                        className="urac-tag"
                        closable
                        onClose={(e) => {
                            e.preventDefault();
                            setValues(values.filter((_, i) => i !== index));
                        }}
                        key={`${name}-tag-${currValue}`}
                    >
                        <Typography.Text ellipsis>{currValue}</Typography.Text>
                    </Tag>
                ))}
                <div className="grow" />
            </div>
            {!values.length && (
                <Item
                    name={`${name}-hidden-input`}
                    rules={[
                        {
                            required: true,
                            message: errorMessage,
                        },
                    ]}
                >
                    <Input type="hidden" />
                </Item>
            )}
        </Item>
    );
}

MultiInput.defaultProps = {
    errorMessage: 'Please answer this question',
};
