import axios from 'axios';
import { Pharmacies } from '../pages/PharmacySelect/PharmacySelect';

interface Config {
    headers?: {
        Authorization?: string;
    };
    params?: {
        [key: string]: string | boolean;
    };
}

export async function getAPIPharmacies(
    zipCode: string,
    nameFilter: string | null,
    token: string,
    isOpen: boolean
): Promise<Pharmacies[]> {
    const { REACT_APP_API_URL, NODE_ENV } = process.env;
    const isTest = NODE_ENV === 'test';
    const url = `${
        (!isTest && REACT_APP_API_URL) || 'http://localhost:1080'
    }/api/pharmacy`;
    const queryString = nameFilter
        ? nameFilter.split(' ').join('%20')
        : 'pharmacy';

    const config: Config = {
        params: { zipCode, queryString, isOpen },
    };

    if (token) {
        config.headers = {
            Authorization: `Bearer ${token}`,
        };
    }
    const response = await axios.get(url, config);

    const { data } = response;
    return data;
}
function numberDayToString(dayNumber: number): string {
    const dayKeys = [
        { day: 0, text: 'Sunday' },
        { day: 1, text: 'Monday' },
        { day: 2, text: 'Tuesday' },
        { day: 3, text: 'Wednesday' },
        { day: 4, text: 'Thursday' },
        { day: 5, text: 'Friday' },
        { day: 6, text: 'Saturday' },
    ];
    const result = dayKeys.find((key) => key.day === dayNumber);
    return result ? result.text : 'Not a valid day';
}
function convertTimeFormat(time: string): string {
    let hours = Number(time.slice(0, 2));
    const mins = time.slice(2) === '00' ? '' : `:${time.slice(2)}`;
    const suffix = hours > 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    return `${hours}${mins}${suffix}`;
}
export interface TimePeriods {
    close: {
        day: number;
        time: string;
    };
    open: {
        day: number;
        time: string;
    };
}

export function findNextOpen(times: TimePeriods[]): string {
    const date = new Date();
    const day = date.getDay();
    const time = `${date.getHours()}${date.getMinutes()}`;
    let currentIndex = times.findIndex((t) => {
        return day === t.close.day;
    });

    if (currentIndex < 0) {
        currentIndex += 1;
        return `Opens ${numberDayToString(
            times[currentIndex].open.day
        )} at ${convertTimeFormat(times[currentIndex]?.open.time)}`;
    }
    if (time < times[currentIndex].open.time) {
        return `Opens at ${convertTimeFormat(times[currentIndex]?.open.time)}`;
    }
    currentIndex =
        times[currentIndex].close.day < times.length ? (currentIndex += 1) : 0;
    return `Opens ${numberDayToString(
        times[currentIndex].open.day
    )} at ${convertTimeFormat(times[currentIndex]?.open.time)}`;
}
