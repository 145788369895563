import { ReactElement } from 'react';
import './progressBar.less';

interface Props {
    currentStep: string;
}
export default function ProgressBar({ currentStep }: Props): ReactElement {
    const stepKeys = [
        { stepName: 'urac', stepNumber: 1 },
        { stepName: 'weight', stepNumber: 1 },
        { stepName: 'which-ear', stepNumber: 1 },
        { stepName: 'pulling', stepNumber: 1 },
        { stepName: 'infection-last-days', stepNumber: 1 },
        { stepName: 'symptoms', stepNumber: 1 },
        { stepName: 'health-questions-complete', stepNumber: 1 },
        { stepName: 'infection-last-days', stepNumber: 1 },
        { stepName: 'informed-consent', stepNumber: 1 },
        { stepName: 'user-sign-up', stepNumber: 2 },
        { stepName: 'sign-up-complete', stepNumber: 2 },
        { stepName: 'left-ear', stepNumber: 3 },
        { stepName: 'right-ear', stepNumber: 3 },
        { stepName: 'video-complete', stepNumber: 3 },
        { stepName: 'pharmacy-search', stepNumber: 4 },
        { stepName: 'pharmacy-select', stepNumber: 4 },
        { stepName: 'confirm-consult', stepNumber: 4 },
    ];
    const key = stepKeys.find((k) => k.stepName === currentStep);
    const step = key ? key.stepNumber : 0;
    return <div className={`progress-bar progress-bar--${step}`} />;
}
