import axios from 'axios';
import { Patient } from './patientAdapter';

interface Config {
    headers?: {
        Authorization: string;
    };
}

export default (token?: string) =>
    async (patientToken: string): Promise<Patient> => {
        const { REACT_APP_API_URL, NODE_ENV } = process.env;
        const isTest = NODE_ENV === 'test';

        const config: Config = {};

        if (token) {
            config.headers = {
                Authorization: `Bearer ${token}`,
            };
        }
        const url = `${
            (!isTest && REACT_APP_API_URL) || 'http://localhost:1080'
        }/api/patient/${patientToken}`;

        const { data } = await axios.get(url, config);
        const { dob } = data;

        return {
            gender: data.gender,
            day: parseInt(dob.substr(6), 10),
            month: parseInt(dob.substr(4, 2), 10),
            year: parseInt(dob.substr(0, 4), 10),
            address: data.address,
        } as Patient;
    };
