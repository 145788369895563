import React, { FC, ReactElement } from 'react';
import { Route } from 'react-router-dom';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { LoadingOutlined } from '@ant-design/icons';

interface Props {
    path: string;
    component: FC;
}

const ProtectedRoute = ({ path, component, ...args }: Props): ReactElement => {
    const { isLoading } = useAuth0();

    if (isLoading) {
        return <LoadingOutlined />;
    }
    return (
        <Route
            path={path}
            component={withAuthenticationRequired(component, {
                onRedirecting: () => <LoadingOutlined />,
                loginOptions: { mode: 'login' },
            })}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...args}
        />
    );
};

export default ProtectedRoute;
