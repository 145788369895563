/* eslint-disable react/jsx-props-no-spreading */
import {
    ReactElement,
    useContext,
    useEffect,
    useState,
    SyntheticEvent,
} from 'react';

import {
    CheckOutlined,
    LoadingOutlined,
    LockOutlined,
    VideoCameraOutlined,
} from '@ant-design/icons';
import Interweave from 'interweave';
import { Alert, Button, Col, Form, Typography, Upload } from 'antd';
import { UploadFile, UploadChangeParam } from 'antd/lib/upload/interface';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { video as content } from '../../staticContent.json';
import { FormContext } from '../../components/Stepper';
import { getPatientName } from '../../utils/textUtils';
import { ReactComponent as ErrorIcon } from '../../media/error.svg';
import { ReactComponent as SuccessIcon } from '../../media/success.svg';
import './videoUpload.less';

const { Title, Paragraph } = Typography;
const { Item } = Form;
const { REACT_APP_API_URL, NODE_ENV, REACT_APP_AUTH0_AUDIENCE } = process.env;
const isTest = NODE_ENV === 'test';

type Ear = 'left' | 'right';

type Props = {
    ear: Ear;
};

enum VideoStatus {
    AVAILABLE = 'AVAILABLE',
    QUARANTINE = 'QUARANTINE',
    REVIEWING = 'REVIEWING',
}

interface RequestConfig {
    headers: {
        Authorization?: string;
        'Content-Type'?: string;
        'x-goog-content-length-range'?: string;
    };
    timeout?: number;
}

interface MediaDetails {
    gcpUrl: string;
    uuid: string;
}

interface ErrorParams {
    message?: string;
    timeout?: NodeJS.Timeout;
}

const mediaEndpoint = `${
    (!isTest && REACT_APP_API_URL) || 'http://localhost:1080'
}/api/media`;

export default ({ ear }: Props): ReactElement => {
    const form = useContext(FormContext);
    const { getAccessTokenSilently, isAuthenticated, loginWithRedirect } =
        useAuth0();
    const contentSpecificToEar = ear === 'left' ? content.ear_1 : content.ear_2;
    const [uploadStatus, setUploadStatus] = useState<string>('pending');
    const [error, setError] = useState<string>('');
    const [showError, setShowError] = useState<boolean>(false);
    const [showSlowMessage, setShowSlowMessage] = useState<boolean>(false);
    const [uploadedFile, setUploadedFile] = useState<File | null>(null);
    const [videoUrl, setVideoUrl] = useState<string | null>(null);
    const [videoTitle, setVideoTitle] = useState<string>('');
    const handleError = ({ message, timeout }: ErrorParams): void => {
        if (timeout) clearTimeout(timeout);
        setError(message ?? 'An error occured. Please try again.');
        setShowError(true);
        setUploadStatus('error');
    };

    const fileUploadProps = {
        name: `video-${ear}`,
        accept: '.mp4,.mov',
        action: '',
        maxCount: 1,
        multiple: false,
        showUploadList: false,
        beforeUpload(file: UploadFile) {
            setVideoTitle(file.name);
            if (file.size && file.size <= 4000000000) {
                return true;
            }
            handleError({ message: 'Your file was too big.' });
            return Upload.LIST_IGNORE;
        },
        customRequest() {
            if (!uploadedFile) {
                handleError({ message: 'You must upload a file.' });
                return;
            }

            // show info message in case of slow server response
            const slowMessage = setTimeout(() => {
                setShowSlowMessage(true);
            }, 5000);

            const getBearerToken = async (): Promise<string | null> => {
                if (isAuthenticated) {
                    return `Bearer ${await getAccessTokenSilently({
                        audience: REACT_APP_AUTH0_AUDIENCE,
                    })}`;
                }
                loginWithRedirect({ mode: 'signUp' });

                handleError({
                    message: 'You must be logged in to upload a video.',
                    timeout: slowMessage,
                });
                return null;
            };

            const getSignedUrl = async (
                token: string
            ): Promise<MediaDetails | null> => {
                const config: RequestConfig = {
                    headers: { Authorization: token },
                };
                return axios
                    .get(`${mediaEndpoint}/gcp_url`, config)
                    .then(({ data }) => {
                        return data;
                    })
                    .catch(() => {
                        handleError({ timeout: slowMessage });
                        return null;
                    });
            };

            const postVideo = async (
                token: string,
                videoId: string
            ): Promise<void> => {
                const config: RequestConfig = {
                    headers: { Authorization: token },
                };
                return axios
                    .post(
                        `${mediaEndpoint}/id/${videoId}`,
                        { title: videoTitle },
                        config
                    )
                    .then(({ data }) => {
                        const { status, id } = data;
                        switch (status) {
                            case VideoStatus.AVAILABLE:
                                form?.setFieldsValue({ [`${ear}Video`]: id });
                                setUploadStatus('success');
                                break;
                            case VideoStatus.QUARANTINE:
                                handleError({
                                    message:
                                        'File did not pass our security checks.',
                                    timeout: slowMessage,
                                });
                                break;
                            default:
                                handleError({ timeout: slowMessage });
                        }
                        clearTimeout(slowMessage);
                    })
                    .catch(() => {
                        handleError({ timeout: slowMessage });
                    });
            };

            const putVideoIntoBucket = async (
                token: string,
                videoDetails: MediaDetails
            ): Promise<void> => {
                const config: RequestConfig = {
                    headers: {
                        'x-goog-content-length-range': '0,4000000000',
                    },
                    // timeout: 10000, // TODO: implement a timeout
                };
                await axios
                    .put(videoDetails.gcpUrl, uploadedFile, config)
                    .then(async ({ status }) => {
                        if (status !== 200) {
                            handleError({
                                timeout: slowMessage,
                            });
                        } else {
                            await postVideo(token, videoDetails.uuid);
                        }
                    })
                    .catch(() => {
                        handleError({
                            timeout: slowMessage,
                        });
                    });
            };

            const completeVideoUpload = async (): Promise<void> => {
                const token = await getBearerToken();
                if (!token) return;
                const videoDetails = await getSignedUrl(token);
                if (!videoDetails) return;
                await putVideoIntoBucket(token, videoDetails);
            };
            completeVideoUpload();
        },
        onChange(info: UploadChangeParam) {
            if (info.file.status === 'error') {
                handleError({});
            }
            setUploadStatus(info.file.status ? info.file.status : 'error');
            setUploadedFile(info.file.originFileObj || null);
            setShowSlowMessage(false);
        },
    };

    useEffect(() => {
        if (uploadStatus !== 'error') setShowError(false);
        if (uploadStatus === 'success' && uploadedFile) {
            setVideoUrl(URL.createObjectURL(uploadedFile));
        }
    }, [uploadStatus, uploadedFile]);

    // video will autoplay which makes iOS actually give a preview
    // once that happens we instantly set the video to the last frame and pause it.
    const onVideoPlay = (e: SyntheticEvent<HTMLVideoElement>): void => {
        e.currentTarget.currentTime = Math.round(
            Math.floor(e.currentTarget.duration)
        );
        e.currentTarget.pause();
    };

    return (
        <div id="video-upload">
            {showError && (
                <Alert
                    type="error"
                    message={
                        <Interweave
                            content={`<strong>Upload failed</strong>: ${error}`}
                        />
                    }
                    onClose={() => setShowError(false)}
                    icon={<ErrorIcon />}
                    showIcon
                    closable
                />
            )}
            {uploadStatus === 'success' && (
                <Alert
                    type="success"
                    message={content.upload_success}
                    icon={<SuccessIcon />}
                    showIcon
                    closable
                />
            )}
            <Title>
                <Interweave content={content.title} />
            </Title>
            <Paragraph>
                <Interweave content={content.desc} />
            </Paragraph>
            <Typography className="video-subtitle">
                <Interweave
                    content={getPatientName(
                        contentSpecificToEar.title,
                        form?.getFieldValue('firstName')
                    )}
                />
            </Typography>
            <Paragraph>
                <Interweave
                    content={getPatientName(
                        contentSpecificToEar.desc,
                        form?.getFieldValue('firstName')
                    )}
                />
            </Paragraph>
            <Item
                name={`${ear}Video`}
                rules={[
                    {
                        required: true,
                        validator: async () => {
                            if (!uploadedFile || uploadStatus !== 'success')
                                return Promise.reject(
                                    new Error('Please upload a video')
                                );
                            if (showError) {
                                return Promise.reject(new Error(error));
                            }
                            return Promise.resolve();
                        },
                    },
                ]}
            >
                <div
                    data-testid="video-picker"
                    placeholder="Video URL"
                    className={`video-container ${
                        uploadStatus === 'success'
                            ? 'video-container-success'
                            : ''
                    }`}
                >
                    {uploadStatus === 'uploading' && (
                        <div className="loading-container">
                            <Typography>
                                <strong>Uploading...</strong>
                            </Typography>
                            {showSlowMessage && (
                                <Typography className="loading-container--slow">
                                    {content.upload_slow}
                                </Typography>
                            )}
                            <LoadingOutlined />
                        </div>
                    )}
                    {uploadStatus === 'success' &&
                        (videoUrl ? (
                            // eslint-disable-next-line jsx-a11y/media-has-caption
                            <video
                                onPlay={onVideoPlay}
                                className="video-container--video"
                                src={videoUrl}
                                autoPlay
                                playsInline
                                muted
                                loop
                            />
                        ) : (
                            <div className="icon-circle">
                                <CheckOutlined className="video-icon video-icon-success" />
                            </div>
                        ))}
                    {uploadStatus !== 'uploading' &&
                        uploadStatus !== 'success' && (
                            <div className="icon-circle">
                                <VideoCameraOutlined className="video-icon" />
                            </div>
                        )}
                </div>
                <Upload {...fileUploadProps}>
                    {uploadStatus !== 'uploading' && (
                        <Button
                            data-testid="upload-video"
                            type={
                                uploadStatus === 'success'
                                    ? 'primary'
                                    : 'default'
                            }
                            className={`upload-video-btn upload-video-btn--${uploadStatus}`}
                        >
                            {contentSpecificToEar.cta}
                        </Button>
                    )}
                </Upload>
            </Item>
            <div className="video-privacy">
                <Col>
                    <LockOutlined className="lock-icon" />
                </Col>
                <Col>
                    <Typography>{content.privacy}</Typography>
                </Col>
            </div>
        </div>
    );
};
