import { ReactElement, useContext, useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Checkbox, Col, Form, Input, Row, Select, Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { RuleRender } from 'antd/lib/form';
import zipValidator from '../../helpers/zipValidator';
import { RadioButton, RadioGroup } from '../../components/HorizontalRadio';
import { profile_new as newProfileContent } from '../../staticContent.json';
import DatePicker from './DatePicker';
import './healthProfile.less';
import InputNumber from '../../components/InputNumber';
import { stateOptions } from './stateOptions';
import { FormContext } from '../../components/Stepper';
import { PolicyModals } from '../../utils/textUtils';
import { getPatient } from './patientAdapter';
import patientHook from './patientApiInterface';

const { Title, Paragraph } = Typography;
const { Item } = Form;

const content = newProfileContent.form;

export default (): ReactElement => {
    const { getAccessTokenSilently } = useAuth0();
    const form = useContext(FormContext);
    const values = form?.getFieldsValue(true);
    const { patientToken, initialConsent } = values;
    const [isAlreadyCreated] = useState<boolean>(!!patientToken);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [consent, setConsent] = useState<boolean>(initialConsent || false);
    const [status, setStatus] = useState(
        initialConsent ? 'ticked' : 'unticked'
    );

    function consentValidator(): RuleRender {
        return () => ({
            validator() {
                if (!consent) {
                    setStatus('error');
                    return Promise.reject(
                        new Error('You need to approve consent to continue.')
                    );
                }
                return Promise.resolve();
            },
        });
    }

    function handleChange(): void {
        if (consent) {
            setStatus('unticked');
        } else {
            setStatus('ticked');
        }
        form?.setFieldsValue({ termsConsent: !consent });
        setConsent(!consent);
    }

    useEffect(() => {
        if (isAlreadyCreated && patientToken) {
            const setPatient = async (): Promise<void> => {
                setIsLoading(true);

                const isTest = process.env.NODE_ENV === 'test';
                const token = !isTest
                    ? await getAccessTokenSilently({
                          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                      })
                    : '';
                const cb = patientHook(token);

                const patient = await getPatient(cb, patientToken);

                form?.setFieldsValue({
                    gender: patient.gender,
                    day: patient.day,
                    month: patient.month,
                    year: patient.year,
                    addressLine1: patient.address.street1,
                    addressLine2: patient.address.street2,
                    city: patient.address.city,
                    state: patient.address.state,
                    homeZip: patient.address.zip,
                });
                setIsLoading(false);
            };

            setPatient();
        }
    }, [
        isAlreadyCreated,
        patientToken,
        form,
        setIsLoading,
        getAccessTokenSilently,
    ]);

    if (isAlreadyCreated) {
        if (isLoading) return <LoadingOutlined />;

        return (
            <div className="health-profile">
                <Title>{`${values.firstName}'s profile information`}</Title>
                <Paragraph>
                    Please confirm the information below before continuing
                </Paragraph>
                <div className="details-container">
                    <Typography className="details-title">
                        Saved details
                    </Typography>
                    <div className="fields-container">
                        <Item label="Name" className="already-created">
                            <Typography className="form-help-text">
                                {values.firstName} {values.lastName}
                            </Typography>
                        </Item>
                        <Item label="Date of Birth" className="already-created">
                            <Typography className="form-help-text">
                                {values.day}/{values.month}/{values.year}
                            </Typography>
                        </Item>
                        <Item
                            label={content.sex_label}
                            className="already-created"
                        >
                            <Typography className="form-help-text">
                                {values.gender?.substring(0, 1).toUpperCase() +
                                    values.gender?.substring(1)}
                            </Typography>
                        </Item>
                    </div>
                </div>
                <div className="details-container">
                    <Typography className="details-title">
                        Resident address
                    </Typography>
                    <div className="fields-container">
                        <Item
                            name="addressLine1"
                            rules={[{ required: true }]}
                            label="Street address, P.O. box, company name"
                            hasFeedback
                        >
                            <Input data-testid="address-line-1" />
                        </Item>
                        <Item
                            name="addressLine2"
                            label="Suite, apartment, building, etc"
                            hasFeedback
                        >
                            <Input data-testid="address-line-2" />
                        </Item>
                        <Item
                            name="city"
                            rules={[{ required: true }]}
                            label="City"
                            hasFeedback
                        >
                            <Input data-testid="city" />
                        </Item>
                        <Row gutter={12}>
                            <Col span={12}>
                                <Item
                                    name="state"
                                    rules={[{ required: true }]}
                                    label="State"
                                    hasFeedback
                                >
                                    <Select
                                        data-testid="state"
                                        options={stateOptions}
                                        className="state-select"
                                    />
                                </Item>
                            </Col>
                            <Col span={12}>
                                <Item
                                    name="homeZip"
                                    label={content.zip_label}
                                    rules={[
                                        { required: true },
                                        zipValidator('homeZip'),
                                    ]}
                                    hasFeedback
                                >
                                    <InputNumber data-testid="zip-code" />
                                </Item>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="health-profile">
            <Title>{content.title}</Title>
            <Paragraph>{content.desc}</Paragraph>
            <div className="details-container">
                <Typography className="details-title">Basic details</Typography>
                <div className="fields-container">
                    <DatePicker />
                    <Item
                        name="firstName"
                        rules={[{ required: true }]}
                        label={content.first_name_label}
                        className="no-top-margin"
                        hasFeedback
                    >
                        <Input data-testid="first-name" />
                    </Item>
                    <Item
                        name="lastName"
                        rules={[{ required: true }]}
                        label={content.last_name_label}
                        hasFeedback
                    >
                        <Input data-testid="last-name" />
                    </Item>
                    <Item
                        label={content.sex_label}
                        rules={[{ required: true }]}
                        required
                    >
                        <Typography className="form-help-text">
                            {content.sex_desc}
                        </Typography>
                        <Item name="gender" rules={[{ required: true }]}>
                            <RadioGroup data-testid="gender-select">
                                <RadioButton
                                    value="male"
                                    data-testid="gender-male"
                                >
                                    Male
                                </RadioButton>
                                <RadioButton value="female">Female</RadioButton>
                            </RadioGroup>
                        </Item>
                    </Item>
                </div>
            </div>
            <div className="details-container">
                <Typography className="details-title">
                    Resident address
                </Typography>
                <div className="fields-container">
                    <Item
                        name="addressLine1"
                        rules={[{ required: true }]}
                        label="Street address, P.O. box, company name"
                        hasFeedback
                    >
                        <Input data-testid="address-line-1" />
                    </Item>
                    <Item
                        name="addressLine2"
                        label="Suite, apartment, building, etc"
                        hasFeedback
                    >
                        <Input data-testid="address-line-2" />
                    </Item>
                    <Item
                        name="city"
                        rules={[{ required: true }]}
                        label="City"
                        hasFeedback
                    >
                        <Input data-testid="city" />
                    </Item>
                    <Row gutter={12}>
                        <Col span={12}>
                            <Item
                                name="state"
                                rules={[{ required: true }]}
                                label="State"
                                hasFeedback
                            >
                                <Select
                                    data-testid="state"
                                    options={stateOptions}
                                    className="state-select"
                                />
                            </Item>
                        </Col>
                        <Col span={12}>
                            <Item
                                name="homeZip"
                                label={content.zip_label}
                                rules={[
                                    { required: true },
                                    zipValidator('homeZip'),
                                ]}
                                hasFeedback
                            >
                                <InputNumber data-testid="zip-code" />
                            </Item>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className={`terms-container ${status}`}>
                <Typography.Text strong>I agree to:</Typography.Text>
                <ul>
                    <li>
                        <PolicyModals key="policy-modals">
                            {
                                '{Terms of Service}, {Privacy Policy}, {Notice of privacy practices}'
                            }
                        </PolicyModals>
                    </li>
                    <li key="modal-description">
                        Receiving consultation related text messages and emails
                    </li>
                </ul>
                <Item name="termsConsent" rules={[consentValidator()]}>
                    <Checkbox
                        value={consent}
                        checked={consent}
                        className="consent-checkbox"
                        onChange={() => {
                            handleChange();
                        }}
                    >
                        I&apos;m ok with this
                    </Checkbox>
                </Item>
            </div>
        </div>
    );
};
