import { VideoCameraOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import Interweave from 'interweave';
import { ReactElement } from 'react';
import { account } from '../../staticContent.json';
import mixpanel, { MixpanelLabels } from '../../utils/mixpanel';
import ConsultDetails from '../../components/ConsultDetails/ConsultDetails';

interface Props {
    orderId: string;
    link: string;
    patientName: string;
    bookingTime: string;
    paymentId: string;
    consultId: string;
    transferred: boolean;
}

export default function Card({
    orderId,
    link,
    patientName,
    bookingTime,
    paymentId,
    consultId,
    transferred,
}: Props): ReactElement {
    const isWithinLastHour =
        new Date(bookingTime) > new Date(new Date().getTime() - 3600000);
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const bookingDateTime = new Date(bookingTime).toLocaleString('en-US', {
        timeZone: timezone,
        weekday: 'long',
        month: 'long',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
        timeZoneName: 'short',
    });

    const onClick = (): void => {
        mixpanel.track(MixpanelLabels.JOIN_VIDEO_CONSULTATION);
        window.open(link, '_blank');
    };

    return (
        <div className="consultation-card">
            <div>
                <strong
                    className={`chip ${isWithinLastHour ? 'future' : 'past'}`}
                >
                    {isWithinLastHour
                        ? account.future_label
                        : account.past_label}
                </strong>
            </div>

            <strong>{patientName}</strong>
            <p>Booked: {bookingDateTime}</p>

            {isWithinLastHour && (
                <>
                    <Interweave content={account.consult_desc} />
                    <div className="href-button-container">
                        <Button
                            type="primary"
                            block
                            className="primary-form-cta"
                            onClick={onClick}
                        >
                            <div>
                                {account.cta}
                                <VideoCameraOutlined className="cta-icon" />
                            </div>
                        </Button>
                    </div>
                    <hr />
                    <p>
                        Need to cancel? <br />
                        <a
                            className="contact-number"
                            href="tel:+1 855-618-0190"
                        >
                            Contact customer support
                        </a>
                    </p>
                </>
            )}

            {!isWithinLastHour && (
                <ConsultDetails
                    orderId={orderId}
                    consultId={consultId}
                    patientName={patientName}
                    paymentId={paymentId}
                    bookingDateTime={bookingDateTime}
                    transferred={transferred}
                />
            )}
        </div>
    );
}
