import { Button, Modal, Row, Typography, Select } from 'antd';
import { InfoCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { useAuth0 } from '@auth0/auth0-react';
import { ReactElement, useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { getUserHealthProfiles } from './userHealthProfileAdapter';
import { FormContext } from '../../components/Stepper';
import userHealthProfileHook from './userHealthProfileApiInterface';

import './userHealthProfiles.less';

const { Text, Title } = Typography;
const { Option } = Select;

export interface HealthProfiles {
    id: 'number';
    userId: 'string';
    patientToken: 'string';
    firstName: 'string';
    lastName: 'string';
    termsConsent: 'boolean';
    informedConsent: 'boolean';
}

export default function UserHealthProfiles(): ReactElement {
    const form = useContext(FormContext);
    const history = useHistory();
    const patientToken = form?.getFieldValue('patientToken');
    const { user, getAccessTokenSilently } = useAuth0();
    const [isLoading, setIsLoading] = useState(true);
    const [isInfoModalVisible, setInfoModalVisible] = useState(false);
    const [selectedHealthProfile, setSelectedHealthProfile] =
        useState(patientToken);
    const [healthProfiles, setHealthProfiles] = useState<HealthProfiles[]>([]);

    const handleCancel = (): void => {
        setInfoModalVisible(false);
    };

    const handleOpenModal = (): void => {
        setInfoModalVisible(true);
    };

    const handleChange = (selected: string): void => {
        setSelectedHealthProfile(selected);
    };

    const handleClick = (): void => {
        const healthProfile = healthProfiles.find(
            (profile) => profile.patientToken === selectedHealthProfile
        );

        if (healthProfile) {
            form?.resetFields();
            localStorage.removeItem('formData');
            form?.setFieldsValue({
                firstName: healthProfile.firstName,
                lastName: healthProfile.lastName,
                termsConsent: healthProfile.termsConsent,
                informedConsent: healthProfile.informedConsent,
                patientToken: healthProfile.patientToken,
            });
            history.push('/form/health-profile');
        }
    };

    const handleNewClick = (): void => {
        form?.resetFields();
        localStorage.removeItem('formData');
        history.push('/form/new-health-profile');
    };

    useEffect(() => {
        const setUserHealthProfiles = async (): Promise<void> => {
            setIsLoading(true);

            const isTest = process.env.NODE_ENV === 'test';

            const token = !isTest
                ? await getAccessTokenSilently({
                      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                  })
                : '';
            const cb = userHealthProfileHook(token);

            const id = !isTest && user?.sub ? user.sub : 'test';
            const userHealthProfiles = await getUserHealthProfiles(cb, id);

            setHealthProfiles(userHealthProfiles);
            setSelectedHealthProfile(userHealthProfiles[0]?.patientToken);
            setIsLoading(false);
        };

        if (user) {
            setUserHealthProfiles();
        }
    }, [user, getAccessTokenSilently]);

    if (isLoading) return <LoadingOutlined />;

    return (
        <>
            <Row>
                <Title style={{ display: 'flex' }}>
                    Who in your family needs care?
                    <Button type="link" onClick={handleOpenModal}>
                        <InfoCircleOutlined />
                    </Button>
                </Title>
            </Row>
            <Text strong>
                Choose patient&nbsp;
                <Text className="required">*</Text>
            </Text>
            <Select
                defaultValue={selectedHealthProfile}
                onChange={handleChange}
                className="choosePatient"
            >
                {healthProfiles.map((healthProfile) => (
                    <Option value={healthProfile.patientToken}>
                        {healthProfile.firstName} {healthProfile.lastName}
                    </Option>
                ))}
            </Select>
            <Text strong>New Patient?</Text>
            <br />
            <Button className="buttonLink" type="link" onClick={handleNewClick}>
                Create a new health profile
            </Button>
            <Modal
                title="Personal profiles"
                footer={null}
                visible={isInfoModalVisible}
                onOk={handleCancel}
                onCancel={handleCancel}
                className="modal"
            >
                If you&apos;ve created an account for a family member before, we
                securely store a saved profile so it&apos;s faster for you to
                get care on the next visit. This confidential data remains
                private and is never sold to 3rd parties.
            </Modal>
            <Row>
                <Button
                    type="primary"
                    block
                    className="primary-form-cta"
                    onClick={handleClick}
                >
                    Next
                </Button>
            </Row>
        </>
    );
}
