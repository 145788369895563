import axios from 'axios';
import { OrderCallbackInterface, OrderInterface } from './orderAdapter';

interface Config {
    headers?: {
        Authorization: string;
    };
}

export default (token?: string) =>
    async (order: OrderInterface): Promise<OrderCallbackInterface> => {
        const { REACT_APP_API_URL, NODE_ENV } = process.env;
        const isTest = NODE_ENV === 'test';

        const config: Config = {};

        if (token) {
            config.headers = {
                Authorization: `Bearer ${token}`,
            };
        }

        const url = `${
            (!isTest && REACT_APP_API_URL) || 'http://localhost:1080'
        }/api/orders`;
        const response = await axios.post(url, order, config);

        const { data } = response;

        return data;
    };
