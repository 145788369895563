interface IdleTimerOptions {
    timeoutMinutes: number;
    warningMinutes?: number | undefined;
    onTimeout: () => void;
    onWarning?: () => void | undefined;
}

class IdleTimer {
    private timeoutMinutes: IdleTimerOptions['timeoutMinutes'];

    private warningMinutes?: IdleTimerOptions['warningMinutes'];

    onTimeout: IdleTimerOptions['onTimeout'];

    onWarning?: IdleTimerOptions['onWarning'];

    private eventHandler: () => void;

    private interval: NodeJS.Timeout;

    private expiredTime: number;

    private warningTime: number;

    constructor({
        warningMinutes,
        onWarning,
        timeoutMinutes,
        onTimeout,
    }: IdleTimerOptions) {
        this.timeoutMinutes = timeoutMinutes;
        this.warningMinutes = warningMinutes;
        this.onTimeout = onTimeout;
        this.onWarning = onWarning;
        this.eventHandler = this.updateExpiredTime.bind(this);
        this.expiredTime = Date.now() + timeoutMinutes * 60000;
        this.warningTime =
            warningMinutes && onWarning
                ? this.expiredTime - warningMinutes * 60000
                : 0;

        this.track();
        this.interval = this.startInterval();
    }

    private track(): void {
        window.addEventListener('click', this.eventHandler);
        window.addEventListener('mousemove', this.eventHandler);
        window.addEventListener('scroll', this.eventHandler);
        window.addEventListener('keydown', this.eventHandler);
    }

    updateExpiredTime(): void {
        this.expiredTime = Date.now() + this.timeoutMinutes * 60000;
        this.warningTime =
            this.warningMinutes && this.onWarning
                ? this.expiredTime - this.warningMinutes * 60000
                : 0;
    }

    cleanUp(): void {
        clearInterval(this.interval);
        window.removeEventListener('click', this.eventHandler);
        window.removeEventListener('mousemove', this.eventHandler);
        window.removeEventListener('scroll', this.eventHandler);
        window.removeEventListener('keydown', this.eventHandler);
    }

    private startInterval(): NodeJS.Timeout {
        return setInterval(() => {
            if (this.onWarning && this.warningMinutes) {
                if (this.warningTime < Date.now()) {
                    this.onWarning();
                }
            }

            if (this.expiredTime < Date.now()) {
                this.onTimeout();
                this.cleanUp();
            }
        }, 1000);
    }
}

export default IdleTimer;
