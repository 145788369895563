import { useAuth0 } from '@auth0/auth0-react';
import { Button, Modal } from 'antd';
import { useEffect, useState } from 'react';
import { ReactElement } from 'react-markdown/lib/react-markdown';
import { useHistory } from 'react-router-dom';
import IdleTimer from '../../utils/IdleTimer';
import Auth0Wrapper from '../Auth0Wrapper';

import './InactiveModal.less';

const InactiveModal = (): ReactElement => {
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const { user } = useAuth0();
    const [timer, setTimer] = useState<IdleTimer | undefined>(undefined);
    const history = useHistory();

    useEffect(() => {
        if (!user) return;
        if (timer) return;

        const newTimer = new IdleTimer({
            warningMinutes: 2,
            onWarning: () => setIsVisible(true),
            timeoutMinutes: 20,
            onTimeout: async () => {
                history.push('/inactivity');
                setIsVisible(false);
            },
        });

        setTimer(newTimer);
    }, [user, history, setTimer, timer]);

    return (
        <Modal
            visible={isVisible}
            title="You’re about to be 
        signed out"
            className="modal"
            onCancel={() => setIsVisible(false)}
            footer={[
                <Button type="primary" onClick={() => setIsVisible(false)}>
                    Continue
                </Button>,
            ]}
        >
            <p>
                We automatically sign you out if you&apos;ve been inactive for a
                while to protect your privacy and security.
            </p>
            <p className="staySignedButton">
                Click Continue to stay signed in.
            </p>
        </Modal>
    );
};

export default Auth0Wrapper(InactiveModal, window.location.origin);
